export default [
  { id: 1, name: 'Офис Лемана Тех', regionId: 1 },
  { id: 2, name: 'Офис Казахстан 17', regionId: 5 },
  { id: 3, name: 'Центральный офис', regionId: 1 },
  { id: 4, name: 'Ижевск', regionId: 2 },
  { id: 5, name: 'Казань 1', regionId: 2 },
  { id: 6, name: 'Казань 2', regionId: 2 },
  { id: 7, name: 'Казань 3', regionId: 2 },
  { id: 8, name: 'Киров', regionId: 2 },
  { id: 9, name: 'Набережные Челны', regionId: 2 },
  { id: 10, name: 'Нижний Новгород', regionId: 2 },
  { id: 11, name: 'Пермь', regionId: 2 },
  { id: 13, name: 'Владикавказ', regionId: 3 },
  { id: 14, name: 'Волгоград', regionId: 2 },
  { id: 15, name: 'Волжский', regionId: 2 },
  { id: 16, name: 'Воронеж', regionId: 3 },
  { id: 17, name: 'Воронеж 2', regionId: 3 },
  { id: 18, name: 'Краснодар 1', regionId: 3 },
  { id: 19, name: 'Краснодар 3', regionId: 3 },
  { id: 20, name: 'Курск', regionId: 3 },
  { id: 21, name: 'Липецк', regionId: 3 },
  { id: 22, name: 'Мега-Адыгея', regionId: 3 },
  { id: 23, name: 'Новороссийск', regionId: 3 },
  { id: 24, name: 'Ростов 1', regionId: 3 },
  { id: 25, name: 'Ростов 2', regionId: 3 },
  { id: 26, name: 'Ростов 3', regionId: 3 },
  { id: 27, name: 'Ставрополь', regionId: 3 },
  { id: 28, name: 'Екатеринбург', regionId: 4 },
  { id: 29, name: 'Екатеринбург 2', regionId: 4 },
  { id: 30, name: 'Магнитогорск', regionId: 4 },
  { id: 31, name: 'Оренбург', regionId: 2 },
  { id: 32, name: 'Самара 1', regionId: 4 },
  { id: 33, name: 'Самара 2', regionId: 4 },
  { id: 34, name: 'Самара 3', regionId: 4 },
  { id: 35, name: 'Саранск', regionId: 4 },
  { id: 36, name: 'Саратов', regionId: 4 },
  { id: 37, name: 'Тольятти', regionId: 4 },
  { id: 38, name: 'Ульяновск', regionId: 4 },
  { id: 39, name: 'Уфа 1', regionId: 4 },
  { id: 40, name: 'Уфа 2', regionId: 4 },
  { id: 41, name: 'Челябинск', regionId: 4 },
  { id: 42, name: 'Алматы 375', regionId: 5 },
  { id: 43, name: 'Выхино', regionId: 5 },
  { id: 44, name: 'Зил', regionId: 5 },
  { id: 45, name: 'Истра', regionId: 5 },
  { id: 46, name: 'Киевское шоссе', regionId: 5 },
  { id: 47, name: 'Красногорск', regionId: 5 },
  { id: 48, name: 'Лефортово', regionId: 5 },
  { id: 49, name: 'Наро-Фоминск', regionId: 5 },
  { id: 50, name: 'Новая Рига', regionId: 5 },
  { id: 52, name: 'Рязанский', regionId: 5 },
  { id: 53, name: 'Троицк', regionId: 5 },
  { id: 54, name: 'Юдино', regionId: 5 },
  { id: 55, name: 'Архангельск', regionId: 6 },
  { id: 56, name: 'Калининград', regionId: 6 },
  { id: 57, name: 'Петрозаводск', regionId: 6 },
  { id: 58, name: 'Псков', regionId: 6 },
  { id: 59, name: 'Санкт-Петербург (Выборгское)', regionId: 6 },
  { id: 60, name: 'Санкт-Петербург (Московское)', regionId: 6 },
  { id: 61, name: 'Санкт-Петербург (Парашютная)', regionId: 6 },
  { id: 62, name: 'Санкт-Петербург (Петергофское)', regionId: 6 },
  { id: 63, name: 'Санкт-Петербург (Руставели)', regionId: 6 },
  { id: 64, name: 'Санкт-Петербург (Таллинское)', regionId: 6 },
  { id: 65, name: 'Санкт-Петербург (Уральская)', regionId: 6 },
  { id: 66, name: 'Санкт-Петербург 1', regionId: 6 },
  { id: 67, name: 'Санкт-Петербург 2', regionId: 6 },
  { id: 68, name: 'Санкт-Петербург 3', regionId: 6 },
  { id: 69, name: 'Санкт-Петербург 4', regionId: 6 },
  { id: 70, name: 'Иваново', regionId: 7 },
  { id: 71, name: 'Калуга', regionId: 7 },
  { id: 72, name: 'Кострома', regionId: 7 },
  { id: 73, name: 'Пенза', regionId: 7 },
  { id: 74, name: 'Рязань', regionId: 7 },
  { id: 75, name: 'Смоленск', regionId: 7 },
  { id: 76, name: 'Тверь', regionId: 7 },
  { id: 77, name: 'Тула', regionId: 7 },
  { id: 78, name: 'Череповец', regionId: 7 },
  { id: 79, name: 'Ярославль 1', regionId: 7 },
  { id: 80, name: 'Ярославль 2', regionId: 7 },
  { id: 81, name: 'Барнаул 1', regionId: 8 },
  { id: 82, name: 'Барнаул 2', regionId: 8 },
  { id: 83, name: 'Новокузнецк', regionId: 8 },
  { id: 84, name: 'Новосибирск 1', regionId: 8 },
  { id: 85, name: 'Новосибирск 2', regionId: 8 },
  { id: 86, name: 'Новосибирск 3', regionId: 8 },
  { id: 87, name: 'Новосибирск 4', regionId: 8 },
  { id: 88, name: 'Омск 1', regionId: 8 },
  { id: 89, name: 'Омск 3', regionId: 8 },
  { id: 90, name: 'Сургут', regionId: 8 },
  { id: 91, name: 'Тюмень', regionId: 8 },
  { id: 92, name: 'Тюмень 2', regionId: 8 },
  { id: 93, name: 'Владивосток', regionId: 9 },
  { id: 94, name: 'Иркутск', regionId: 9 },
  { id: 95, name: 'Кемерово', regionId: 9 },
  { id: 96, name: 'Кемерово 2', regionId: 9 },
  { id: 97, name: 'Красноярск 1', regionId: 9 },
  { id: 98, name: 'Красноярск 2', regionId: 9 },
  { id: 99, name: 'Красноярск 3', regionId: 9 },
  { id: 100, name: 'Хабаровск', regionId: 9 },
  { id: 101, name: 'Хабаровск 2', regionId: 9 },
  { id: 102, name: 'Алтуфьево', regionId: 10 },
  { id: 103, name: 'Варшавское шоссе', regionId: 10 },
  { id: 104, name: 'Домодедово', regionId: 10 },
  { id: 105, name: 'Жуковский', regionId: 10 },
  { id: 106, name: 'Зеленоград', regionId: 10 },
  { id: 107, name: 'Каширское', regionId: 10 },
  { id: 108, name: 'Климовск', regionId: 10 },
  { id: 109, name: 'Клин', regionId: 10 },
  { id: 110, name: 'Люберцы', regionId: 10 },
  { id: 111, name: 'Мытищи', regionId: 10 },
  { id: 112, name: 'Ногинск', regionId: 10 },
  { id: 113, name: 'Пушкино', regionId: 10 },
  { id: 114, name: 'Химки', regionId: 10 },
  { id: 115, name: 'Шолохово', regionId: 10 },
  { id: 116, name: 'ДС МСК Белая Дача', regionId: 11 },
  { id: 117, name: 'ДС МСК Мытищи', regionId: 11 },
  { id: 118, name: 'Интернет-магазин Москва', regionId: 11 },
  { id: 119, name: 'Интернет-магазин Санкт-Петербург', regionId: 11 },
  { id: 120, name: 'РЦ "Северная Звезда"', regionId: 11 },
  { id: 121, name: 'Склад Новосибирск', regionId: 11 },
  { id: 122, name: 'Склад Самара', regionId: 11 },
  { id: 123, name: 'Склад Хабаровск', regionId: 11 },
  { id: 124, name: 'Склад Южные врата', regionId: 11 },
  { id: 125, name: 'СК Аврора', regionId: 11 },
  { id: 126, name: 'СК Иркутск', regionId: 11 },
  { id: 127, name: 'СК Уфа', regionId: 11 },
  { id: 128, name: 'ЦИЗ Елино', regionId: 11 },
  { id: 129, name: 'ЦИЗ Санкт-Петербург Южный', regionId: 11 },
  { id: 130, name: 'СК Ростов-на-Дону', regionId: 11 },
  { id: 131, name: 'СК Краснодар', regionId: 11 },
  { id: 132, name: 'СК ЕКБ Системный', regionId: 11 },
  { id: 133, name: 'Техническая команда', regionId: 12 },
  { id: 134, name: 'Сокольники', regionId: 5 },
];
