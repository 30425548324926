<template>
  <div class="club">
    <div class="divider"></div>
    <div class="market__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div class="club__intro" :style="`background-image: url('${$root.isDesktop ? clubCover : ''}');`">
      <img :src="!$root.isDesktop ? clubCoverMobile : ''" alt="">
      <div class="club__info" v-if="$root.isDesktop">
        <h2 class="club__intro-title">{{ clubTitle }}</h2>
        <div class="club__intro-text" v-html="clubSubtitle"></div>
      </div>
    </div>
    <div class="club__info_mobile" v-if="$root.isMobile">
      <h2 class="club__intro-title">{{ clubTitle }}</h2>
      <div class="club__intro-text" v-html="clubSubtitle"></div>
    </div>
    <div class="club__tabs">
      <Tabs
        :items="tabs"
        :activeItem="selectedTab"
        @changed="changeTab($event, false)"
      />
    </div>
    <div class="divider"></div>
    <div v-show="selectedTab === 0">
      <Loader v-if="loading" size="24" />
      <div v-else>
        <div class="club__goal" v-if="isClubGoal">
          <Goal
            :formatTotalKm="formatTotalKm"
            :formatCurrentKm="formatCurrentKm"
            :totalKm="goal ? goal.necessaryDistance : 0"
            :currentKm="goal ? goal.completedDistance : 0"
            :currentMoney="goal && goalShowCharity ? `${goal.completedAmount} ₽` : ''"
            :localizedText="{
              distance: 'вместе преодолено', total: 'Вместе преодолеть:', amount: 'собрано во благо',
            }"
            fullWidth
            @distance-question="onShowDistance()"
            @money-question="onShowCharity()"
          ></Goal>
        </div>
        <div class="club__nominations" v-show="!loading">
          <SectionContainer
            title="Рейтинг"
            icon="trophy"
            class="club__nominations-container"
            @goto="onGoTo('Nominations', 'Рейтинг')"
          >
            <div class="nominations">
              <div v-if="isTeamsEnabled && showTeamRatings" class="nominations__types">
                <Button
                  v-for="(item, i) in nominationTypes"
                  :key="i"
                  :variant="i === selectedNominationType ? 'primary' : 'secondary'"
                  :disabled="isRatingTypeDisabled(i)"
                  size="md"
                  mobile
                  @click="onChangeNominationType(i)"
                >
                  {{ item }}
                </Button>
              </div>
              <div
                class="nominations__slider-container"
                :style="{
                  'opacity': (hiddenNominationsSlider ? '0' : '1'),
                  'min-height': (nominationsSliderHeight ? nominationsSliderHeight + 'px' : 'auto'),
                }"
                :id="nominationsSliderId"
              >
                <div v-if="!nominationsUpdating && selectedNominationType === 0" class="nominations__slider">
                  <Slider
                    v-if="nominationsActive ? nominationsActive.total > 0 : false"
                    cols="1"
                    :slidesPerView="1"
                    :loop="true"
                    :navigationOffset="nominationsNavigationOffset"
                    :paginationOffset="nominationsPaginationOffset"
                    class="nominations-slider"
                  >
                    <NominationCard
                      v-for="item in nominationsActive.values"
                      :key="item.nominationId"
                      type="personal"
                      :state="nominationsState"
                      :image="getNominationImage(item)"
                      :nominationText="getNominationDates(item)"
                      :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                      :title="item.title"
                      :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                      :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                      :ratingText="{ general: 'Текущий рейтинг', personal: 'Вы в рейтинге' }"
                      :values="getNominationRating(item)"
                      @show-details="onShowRating(item)"
                      @locked-click="onShowOnboarding()"
                    ></NominationCard>
                  </Slider>
                </div>
                <div v-if="!nominationsUpdating && selectedNominationType === 1" class="nominations__slider">
                  <Slider
                    v-if="teamNominationsActive ? teamNominationsActive.total > 0 : false"
                    cols="1"
                    :slidesPerView="1"
                    :loop="true"
                    :navigationOffset="nominationsNavigationOffset"
                    :paginationOffset="nominationsPaginationOffset"
                    filterByGroupId="1"
                    class="nominations-slider"
                  >
                    <NominationCard
                      v-for="item in teamNominationsActive.values"
                      :key="item.nominationId"
                      type="personal"
                      state="in-progress"
                      class="team-rating"
                      data-group-id="1"
                      :nominationText="getNominationDates(item)"
                      :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                      :title="item.title"
                      :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                      :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                      :ratingText="{ general: 'Текущий рейтинг', personal: 'Команда в рейтинге' }"
                      :values="getNominationRating(item, true)"
                    ></NominationCard>
                  </Slider>
                </div>
              </div>
            </div>
          </SectionContainer>
        </div>
        <div class="club__tasks">
          <SectionContainer
            title="Задания"
            icon="file-done"
            @goto="onGoTo('TasksAvailable', 'Задания')"
          >
            <div class="recommended__slider">
              <Slider
                v-if="tasksAvailable ? tasksAvailable.total > 0 : false"
                :cols="cols"
                :noPagination="noPagination"
                class="tasks-slider"
              >
                <TaskCard
                  v-for="(task, i) in tasksAvailable.values"
                  :key="i"
                  :stateLabels="stateLabels"
                  :timeLabels="timeLabels"
                  :styleLabels="styleLabels"
                  :image="task.images.mobile"
                  :action="{ func: () => onShowTask(i) }"
                  type="coins"
                  state="available"
                  :title="task.title"
                  :subtitle="task.shortDescription"
                  :cost="getTaskRewardRange(task)"
                  :date="`${task.endDate}`"
                  :itemsTotal="0"
                  :itemsDone="0"
                  :complexity="task.complexity"
                  :isSpecial="task.isSpecial || false"
                  :isDaily="task.isDaily || false"
                  :isRepeat="false"
                  :disciplines="getDisciplineIcons(task)"
                ></TaskCard>
              </Slider>
            </div>
          </SectionContainer>
          <div class="club__calendar" v-if="calendarData">
            <div class="banner-wrapper">
              <div v-if="imageUrl" class="banner">
                <router-link to="/calendar">
                  <img :src="imageUrl">
                </router-link>
              </div>
            </div>
          </div>
          <div class="club__market">
            <SectionContainer
              title="Маркет"
              icon="shopping-bag"
              @goto="onGoTo('Market', 'Маркет')"
            >
            <div class="rewards">
              <div class="rewards__slider">
                <Slider
                  v-if="promotions ? promotions.total > 0 : false"
                  :cols="cols"
                  :noPagination="noPagination"
                  class="rewards-slider"
                >
                  <RewardCard
                    v-for="(promotion, i) in promotions.values"
                    :key="i"
                    :image="promotion.images ? promotion.images.mobile : ''"
                    :action="{ func: () => onAction(i) }"
                    type='coins'
                    :stateLabels="rewardsStateLabels"
                    :title="promotion.name"
                    :subtitle="promotion.subTitle"
                    :cost="promotion.priceInPoints"
                    :compactMobile="$root.isMobile"
                  ></RewardCard>
                </Slider>
              </div>
            </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    </div>
    <div v-show="selectedTab === 1">
      <Loader v-if="loading" size="24" />
      <div v-else>
        <div class="club__onboarding">
          <TextBlock
            v-if="textBlockItems.length > 0"
            :items="textBlockItems"
          />
        </div>
        <div class="club__ambassador">
          <Ambassador
            v-if="ambassadorItems.length > 0"
            :type="ambassadorsType || 'general'"
            :title="ambassadorsTitle"
            :items="ambassadorsValues"
          ></Ambassador>
        </div>
        <div class="club__community">
          <div class="banner-wrapper">
            <div v-if="$root.isMobile" class="banner">
              <a :href="bannerCommunity ? bannerCommunity.redirectUrl : ''" target="_blank">
                <img :src="bannerCommunity ? bannerCommunity.mobileImageUrl : ''" />
              </a>
            </div>
            <div v-if="!$root.isMobile" class="banner">
              <a :href="bannerCommunity ? bannerCommunity.redirectUrl : ''" target="_blank">
                <img :src="bannerCommunity ? bannerCommunity.desktopImageUrl : ''" />
              </a>
            </div>
          </div>
          <TopCurators v-if="themeDone && showTopCurators"></TopCurators>
        </div>
      </div>
    </div>
    <div v-show="selectedTab === 2">
      <Loader v-show="loading" size="24" />
      <div v-show="!loading">
        <div v-if="isChatEnabled" class="club__chat">
          <p class="club__chat-title">Клубный чат</p>
          <p class="club__chat-subtitle">Обсуждайте задания, делитесь успехами и поддерживайте друг друга.</p>
          <div class="club__chat-wrapper">
            <div class="chat" id="chat"></div>
          </div>
          <div class="club__chat-hint">
            <span>Необходимо разрешить Telegram доступ к странице. <router-link to="/support">Подробнее в разделе &laquo;Поддержка&raquo; </router-link></span>
          </div>
        </div>
        <div v-if="isChatEnabled">
          <div class="banner-wrapper">
            <div v-if="bannerVk && $root.isMobile" class="banner">
              <a :href="bannerVk ? bannerVk.redirectUrl : ''" target="_blank">
                <img :src="bannerVk ? bannerVk.mobileImageUrl : ''" />
              </a>
            </div>
            <div v-if="bannerVk && !$root.isMobile" class="banner">
              <a :href="bannerVk ? bannerVk.redirectUrl : ''" target="_blank">
                <img :src="bannerVk ? bannerVk.desktopImageUrl : ''" />
              </a>
            </div>
          </div>
        </div>
        <div class="club-chat__banner" v-if="!isChatEnabled">
          <div class="banner-wrapper banner-wrapper_gray">
            <div v-if="$root.isDesktop" class="banner">
              <a :href="linkToChat" target="_blank">
                <img :src="bannerDesktopChat ? bannerDesktopChat : ''" alt="">
              </a>
            </div>
            <div v-if="$root.isMobile" class="banner">
              <a :href="linkToChat" target="_blank">
                <img :src="bannerMobileChat ? bannerMobileChat : ''" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DistanceCharityModal
      :active="distanceCharityModal"
      :type="distanceCharityType"
      :localizedText="distanceCharityLocalizedText"
      moreLink=""
      @close="distanceCharityModal = false"
    />
    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      :isMobileApp="isMobileApp"
      :showBack="true"
      @close="onRulesPrizesClosed"
    />
    <TaskDetailsModal
      :active="taskDetailsModal"
      :state="taskDetailsState"
      :modalOptions="taskDetailsOptions"
      :rating="taskRating"
      @close="onTaskDetailsClose"
      @start="onStart"
    />
    <WelcomeModal
      v-show="welcomeModal"
      :active="welcomeModal"
      :localizedText="welcomeLocalizedText"
      @close="onWelcomeClosed"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import TaskCard from '@sc-component-library/task-card/src/main';
import RewardCard from '@sc-component-library/reward-card/src/main';
import Goal from '@sc-component-library/goal/src/main';
import Ambassador from '@sc-component-library/ambassador/src/main';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import SectionContainer from '@/components/SectionContainer/SectionContainer.vue';
import Menu from '@/components/Menu/Menu.vue';
import TopCurators from '../components/TopCurators/TopCurators.vue';
import TextBlock from '@/components/TextBlock/TextBlock.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import TaskDetailsModal from '@/components/TaskDetailsModal/TaskDetailsModal.vue';
import WelcomeModal from '@/components/WelcomeModal/WelcomeModal.vue';
import IntroImg from '../assets/img/club/club-intro.jpg';
import Coin from '@/assets/img/coin.png';
import GoalCoords from '@/assets/data/goalCoords';
import DistanceCharityModal from '@/components/DistanceCharityModal/DistanceCharityModal.vue';
import {
  formatNumber, addNonBreakingSpaces, formatDateRange,
} from '@/assets/js/helpers';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Club',
  components: {
    Menu,
    Goal,
    TaskCard,
    DistanceCharityModal,
    SectionContainer,
    NominationCard,
    RewardCard,
    TopCurators,
    TextBlock,
    Ambassador,
    RulesPrizesModal,
    TaskDetailsModal,
    WelcomeModal,
  },

  data() {
    return {
      IntroImg,
      hiddenNominationsSlider: false,
      nominationsSliderHeight: 0,
      nominationsSliderId: `nominations__slider-container${Math.random().toString().replace('.', '')}`,
      nominationsUpdating: false,
      nominationTypes: ['Личный', 'Командный'],
      selectedNominationType: null,
      goalCoords: GoalCoords,
      nominationsState: 'in-progress',
      selectedTab: 0,
      contributionPercentage: 0,
      totalDistance: '',
      completedDistance: '',
      distanceCharityModal: false,
      distanceCharityType: '',
      distanceCharityLocalizedText: {
        title: '',
        subtitle: '',
      },
      stateLabels: {
        available: 'до ', inProgress: 'Выполняется', checking: 'Проверяется', completed: 'Выполнено', failed: 'Вы не успели :(',
      },
      styleLabels: {
        special: 'Особое задание', daily: 'Ежедневное', repeat: 'Многоразовое',
      },
      timeLabels: { days: 'д', hours: 'ч' },
      rewardsStateLabels: {
        available: 'В наличии', count: '5 шт.',
      },
      calendarData: [],
      textBlockItems: [],
      ambassadorItems: [],
      loading: false,
      rulesPrizesModal: false,
      rulesPrizesImage: '',
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesTabActive: 0,
      taskDetailsModal: false,
      taskDetailsState: 'in-progress',
      taskDetailsOptions: {},
      taskRating: {},
      linkClassForChat: 'link-for-telegram-chat',
      scriptIdForWidget: 'telegram-chat',
      loadingChat: false,
      welcomeModal: false,
      welcomeLocalizedText: {
        title: '',
        subtitle: '',
        subtitleMarket: `Вам начисленно <b><img src="${Coin}"/> 200</b> приветственных баллов. <br/> Можете потратить их на приятный бонус в маркете`,
        actionPrimary: { text: 'Заполнить анкету', action: this.onWelcomeActionPrimary },
        actionSecondary: { text: 'Напомнить позже', action: this.onWelcomeActionSecondary },
        actionMarket: { action: this.onWelcomeActionMarket },
      },
    };
  },
  computed: {
    ...mapGetters('settings', ['resources', 'options', 'themeDone']),
    ...mapGetters('user', ['profile', 'isMobileApp', 'isAuthenticated']),
    ...mapGetters('goals', ['goal']),
    ...mapGetters('trackers', ['trackers']),
    ...mapGetters('nominations', ['nominationsActive', 'teamNominationsActive']),
    ...mapGetters('exercises', ['tasksAvailable', 'tasksInProgress', 'tasksCompleted', 'task', 'taskPoints', 'taskGuest', 'responseStatus']),
    ...mapGetters('calendar', ['calendar']),
    ...mapGetters('market', ['promotions']),
    ...mapGetters('content', ['onboardingItems', 'contentStatus', 'ambassadors', 'banners', 'introSections']),
    ...mapGetters('calendar', ['calendar']),
    showTopCurators() {
      return this.options ? this.options.showTopCurators === true : false;
    },
    imageUrl() {
      if (!this.calendarData || !this.calendarData.calendarImages) {
        return '';
      }
      return this.$root.isMobile ? this.calendarData.calendarImages.mobile : this.calendarData.calendarImages.desktop;
    },
    formatTotalKm() {
      return this.goal ? formatNumber(this.goal.necessaryDistance, 'км') : '0 км';
    },
    formatCurrentKm() {
      return this.goal ? formatNumber(this.goal.completedDistance, 'км') : '0 км';
    },
    goalShowCharity() {
      return this.options ? this.options.goalShowCharity === true : false;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    showTeamRatings() {
      return this.options ? this.options.showTeamRatings === true : false;
    },
    nominationsNavigationOffset() {
      return {
        default: '0', md: '167', lg: '167', xl: '167',
      };
    },
    nominationsPaginationOffset() {
      return {
        default: '8', md: '12', lg: '12', xl: '12',
      };
    },
    noPagination() {
      return true;
    },
    cols() {
      return {
        default: '1', md: '2', lg: '3', xl: '3',
      };
    },
    ambassadorsType() {
      return this.options ? this.options.ambassadorsType || '' : 'general';
    },
    ambassadorsTitle() {
      return this.resources ? this.resources.ambassadorsTitle || this.ambassadorsTitleDefault : this.ambassadorsTitleDefault;
    },
    ambassadorsTitleDefault() {
      return addNonBreakingSpaces('Колонка Капитана');
    },
    ambassadorsValues() {
      return this.ambassadors ? this.updatedAmbassadors() || [] : [];
    },
    bannerCommunity() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'community')[0] : {};
    },
    bannerVk() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'vk')[0] : {};
    },
    progressTab() {
      return this.selectedTab === 0;
    },
    aboutTab() {
      return this.selectedTab === 1;
    },
    chatTab() {
      return this.selectedTab === 2;
    },
    isFirstLogin() {
      return this.$route.query.isFirstLogin === 'true';
    },
    standardWelcomeModal() {
      return this.options ? this.options.customWelcomeModal !== true : true;
    },
    chatWidgetOptions() {
      return this.options ? this.options.chatWidgetOptions : null;
    },
    clubCoverMobile() {
      return this.introSections ? this.introSections.topSectionMobileImageUrl || '' : '';
    },
    clubCover() {
      return this.introSections ? this.introSections.topSectionDesktopImageUrl || '' : '';
    },
    clubTitle() {
      return this.introSections ? this.introSections.topSectionTitle || '' : '';
    },
    clubSubtitle() {
      return this.introSections ? this.introSections.topSectionShortDescription || '' : '';
    },
    isClubGoal() {
      return this.options ? this.options.showClubGoal !== false : true;
    },
    tabs() {
      return [
        { title: 'Прогресс', enabled: true },
        { title: 'О нас', enabled: true },
        { title: 'Чат', enabled: this.showChat },
      ];
    },
    showChat() {
      return this.options ? this.options.showChat === true : false;
    },
    isChatEnabled() {
      return this.options ? this.options.isChatEnabled !== false : true;
    },
    bannerDesktopChat() {
      return this.resources ? this.resources.chatBanner || '' : '';
    },
    bannerMobileChat() {
      return this.resources ? this.resources.chatBannerMobile || '' : '';
    },
    linkToChat() {
      return this.resources ? this.resources.chatLink || '' : '';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.setTextBlock();
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('goals', ['getGoal']),
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('nominations', ['getNominationsActive', 'getTeamNominationsActive']),
    ...mapActions('exercises', ['getTasksAvailable', 'getTasksInProgress', 'getTasksCompleted', 'getTask', 'getTaskPoints', 'getTaskGuest', 'assignTask']),
    ...mapActions('calendar', ['getCalendar']),
    ...mapActions('market', ['getPromotions']),
    ...mapActions('content', ['getOnboardingItems', 'getAmbassadors', 'getBanners']),

    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.isAuthenticated) {
            if (this.isFirstLogin) {
              this.showWelcome();
            }
            this.getBalance();
            this.getTasksAvailable({ offset: 0, count: 12 });
            this.getTasksInProgress({ offset: 0, count: 12 });
            this.getTasksCompleted({ offset: 0, count: 12 });
            this.getPromotions({ offset: 0, count: 12 });
            this.getCalendarBanner();
            this.getTrackers();
            this.getNominationsActive({ offset: 0, count: 12 })
              .then(() => {
                if (this.isTeamsEnabled && this.showTeamRatings) {
                  this.getTeamNominationsActive({ offset: 0, count: 12 })
                    .then(() => {
                      if (this.nominationsActive ? this.nominationsActive.total === 0 : false) {
                        if (this.teamNominationsActive ? this.teamNominationsActive.total > 0 : false) {
                          this.onChangeNominationType(1);
                        } else {
                          this.onChangeNominationType(0);
                        }
                        return;
                      }
                      this.onChangeNominationType(0);
                      window.addEventListener('hashchange', this.onHashChange);
                      this.onHashChange();
                    });
                  return;
                }
                this.onChangeNominationType(0);
                window.addEventListener('hashchange', this.onHashChange);
                this.onHashChange();
              });
            this.getGoal()
              .then(() => {
                if (this.goal) {
                  this.contributionPercentage = Math.round(this.goal.contribution.contributionPercentage);
                  this.totalDistance = formatNumber(this.goal.contribution.totalDistance, '<span>км</span>');
                  this.completedDistance = formatNumber(this.goal.completedDistance, '<span>км</span>');
                }
              });
          }
        });
    },
    onStart() {
      this.taskDetailsModal = false;
      window.location.hash = '';
      if (this.taskDetailsOptions) {
        if (this.taskDetailsOptions.isTrackerNeeded !== true) {
          const participantExerciseId = uuidv4();
          this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
            .then(() => {
              if (this.responseStatus === 204) {
                window.scrollTo(0, 0);
                const url = `/tasks/in-progress#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
                this.$router.replace(url);
              }
            });
          return;
        }
      }
      if (this.trackers.filter((i) => i.state === 'Connected').length > 0) {
        const participantExerciseId = uuidv4();
        this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
          .then(() => {
            if (this.responseStatus === 204) {
              window.scrollTo(0, 0);
              const url = `/tasks/in-progress#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
              this.$router.replace(url);
            }
          });
        return;
      }
      if (this.$root.showOnboarding) {
        localStorage.setItem('returnToTask', this.taskDetailsExerciseId);
        this.$root.showOnboarding({ step: 'choose' });
      }
    },
    onAction(i) {
      if (this.isMobileApp) {
        this.$root.$goPage('RewardDetails', `/market/reward?id=${this.promotions.values[i].promotionId}&title=Маркет`);
        return;
      }
      this.$router.push({ name: 'RewardDetails', query: { id: this.promotions.values[i].promotionId, from: 'Club' } }).catch(() => {});
    },
    onShowTask(index) {
      const tasks = this.tasksAvailable.values;
      if (tasks) {
        const task = tasks[index];
        localStorage.removeItem('returnToTask');
        window.location.hash = `#!showTask=${task.exerciseId}`;
      }
    },
    onHashChange() {
      if (this.$route.name !== 'Club') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showTask') this.processLoadTask(hash);
      else {
        this.taskDetailsModal = false;
        this.rulesPrizesModal = false;
        this.distanceCharityModal = false;
      }
    },
    onShowDistance() {
      this.distanceCharityType = 'distance';
      const subtitleDefault = addNonBreakingSpaces('Бегайте и ходите. Внесите свой вклад в достижение общей цели.');
      this.distanceCharityLocalizedText = {
        title: 'Вместе преодолеть',
        subtitle: this.goalModalSubtitle ? this.goalModalSubtitle : subtitleDefault,
      };
      this.distanceCharityModal = true;
    },
    onShowCharity() {
      this.distanceCharityType = 'charity';
      this.distanceCharityLocalizedText = {
        title: this.goalModalCharityTitle,
        subtitle: this.goalModalCharitySubtitle,
      };
      this.distanceCharityModal = true;
    },
    onChangeNominationType(index) {
      if (this.selectedNominationType === index) return;
      this.updateHeight();
      this.selectedNominationType = -1;
      setTimeout(() => { this.nominationsUpdating = true; }, 100);
      setTimeout(() => {
        this.selectedNominationType = index;
        this.nominationsUpdating = false;
      }, 400);
    },
    onWelcomeClosed() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/club?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      this.welcomeModal = false;
    },
    onWelcomeActionPrimary() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/club?isShowQuestionnaire=true').catch(() => {});
        return;
      }
      window.location.href = '/club?isShowQuestionnaire=true';
      this.welcomeModal = false;
    },
    onWelcomeActionSecondary() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/club?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      if (!this.showWelcomeModalMarket) {
        window.location.href = '/club';
      } else window.location.href = '/about#textBlock';
      this.welcomeModal = false;
    },
    onWelcomeActionMarket() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/club?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      if (!this.standardWelcomeModal) {
        window.location.href = '/market';
      }
      this.welcomeModal = false;
    },
    onGoTo(routeName, title) {
      if (routeName) this.$router.push({ name: routeName, query: this.isMobileApp ? { title } : {} }).catch(() => {});
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
    },
    onRulesPrizesClosed() {
      window.location.hash = '';
      this.rulesPrizesModal = false;
    },
    onTaskDetailsClose() {
      this.taskDetailsModal = false;
      window.scroll(0, this.pageScrollY);
      window.location.hash = '';
    },
    updatedAmbassadors() {
      return this.ambassadors.map((ambassador) => ({
        name: ambassador.fullName,
        avatar: ambassador.desktopImageUrl,
        avatarMobile: ambassador.mobileImageUrl,
        taskText: ambassador.content,
        quote: ambassador.description,
      }));
    },
    changeTab(tab) {
      this.loading = true;
      this.selectedTab = tab;
      if (tab === 1) {
        this.setTextBlock();
      } else if (tab === 0) {
        this.setProgressTab();
      } else if (tab === 2) {
        this.loading = false;
      }
    },
    initChat() {
      this.addCanonical();

      if (this.hasChat() || this.loadingChat) return;
      if (!this.chatWidgetOptions || !this.chatWidgetOptions.discussion) return;

      this.loadingChat = true;

      const script = document.createElement('script');
      script.id = this.scriptIdForWidget;
      script.type = 'application/javascript';
      script.async = true;
      script.setAttribute('data-telegram-discussion', this.chatWidgetOptions.discussion);
      if (this.chatWidgetOptions.commentsLimit) script.setAttribute('data-comments-limit', this.chatWidgetOptions.commentsLimit);
      if (this.chatWidgetOptions.color) script.setAttribute('data-color', this.chatWidgetOptions.color);
      if (this.$root.isMobile) {
        script.setAttribute('data-height', '300');
      }
      script.onload = () => {
        setTimeout(() => { this.loadingChat = false; }, 1000);
      };
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      setTimeout(() => {
        const chat = document.getElementById('chat');
        if (!chat) return;
        if (chat.childElementCount > 0) return;
        chat.appendChild(script);
      }, 50);
    },
    addCanonical() {
      if (this.hasCanonical()) return;
      const link = document.createElement('link');
      link.setAttribute('class', this.linkClassForChat);
      link.rel = 'canonical';
      link.href = `${window.location.origin}/club`;
      document.head.appendChild(link);
    },
    hasChat() {
      return document.getElementById(this.scriptIdForWidget) !== null;
    },
    hasCanonical() {
      return document.head.getElementsByClassName(this.linkClassForChat).length > 0;
    },
    removeCanonical() {
      const link = document.head.getElementsByClassName(this.linkClassForChat);
      for (let i = 0; i < link.length; i += 1) {
        document.head.removeChild(link[i]);
      }
    },
    updateHeight() {
      const itemsSlider = document.getElementById(this.nominationsSliderId);
      if (itemsSlider === null) return;
      if (itemsSlider.offsetHeight > 0) {
        this.nominationsSliderHeight = itemsSlider.offsetHeight;
      }
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item, isTeam = false) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: isTeam ? item.leaders[i].teamName : `${item.leaders[i].lastName} ${item.leaders[i].firstName}`,
          value: item.leaders[i].points.toString(),
          current: isTeam ? item.leaders[i].isCurrentTeam === true : item.leaders[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    setBannersBlocks() {
      this.getBanners()
        .then(() => {
          if (this.contentStatus === 200) {
            this.bannersItems = this.banners || [];
          }
        });
    },
    getDisciplineIcons(task) {
      const disciplineIconMap = {
        Invite: 'invite',
        Running: 'running',
        Cycling: 'cycling',
        Skiing: 'skiing',
        Swimming: 'swimming',
        Walking: 'walking',
        Quiz: 'quiz',
        GeoLocation: 'geolocation',
        PartnerLink: 'partnerlink',
        Registration: 'registration',
        Participation: 'participation',
        UploadImage: 'uploadimage',
      };
      if (!task || !task.tasks) return [];
      const tasks = task ? task.tasks : null;
      const disciplineIcons = [];
      const uniqueIcons = new Set();
      for (let i = 0; i < tasks.length; i += 1) {
        const discipline = tasks[i].settings.Discipline;
        const icon = disciplineIconMap[discipline] || '';
        if (!uniqueIcons.has(icon)) {
          uniqueIcons.add(icon);
          disciplineIcons.push(icon);
        }
      }
      return disciplineIcons;
    },
    getCalendarBanner() {
      this.getCalendar()
        .then(() => {
          if (this.responseStatus === 200) {
            this.calendarData = this.calendar ? this.calendar : [];
          }
        });
    },
    setTextBlock() {
      this.getOnboardingItems()
        .then(() => {
          if (this.contentStatus === 200) {
            this.textBlockItems = this.onboardingItems || [];
            this.setAmbassadorBlock();
            this.loading = false;
          }
        });
    },
    setAmbassadorBlock() {
      this.getAmbassadors()
        .then(() => {
          if (this.contentStatus === 200) {
            this.ambassadorItems = this.ambassadors || [];
          }
        });
    },
    setProgressTab() {
      this.getCalendarBanner();
      this.getGoal()
        .then(() => {
          if (this.goal) {
            this.contributionPercentage = Math.round(this.goal.contribution.contributionPercentage);
            this.totalDistance = formatNumber(this.goal.contribution.totalDistance, '<span>км</span>');
            this.completedDistance = formatNumber(this.goal.completedDistance, '<span>км</span>');
          }
          this.getTasksAvailable({ offset: 0, count: 12 });
          this.getTasksInProgress({ offset: 0, count: 12 });
          this.getPromotions({ offset: 0, count: 12 });
          this.getNominationsActive({ offset: 0, count: 12 })
            .then(() => {
              if (this.isTeamsEnabled && this.showTeamRatings) {
                this.getTeamNominationsActive({ offset: 0, count: 12 })
                  .then(() => {
                    if (this.nominationsActive ? this.nominationsActive.total === 0 : false) {
                      if (this.teamNominationsActive ? this.teamNominationsActive.total > 0 : false) {
                        this.onChangeNominationType(1);
                      } else {
                        this.onChangeNominationType(0);
                      }
                      return;
                    }
                    this.onChangeNominationType(0);
                    window.addEventListener('hashchange', this.onHashChange);
                    this.onHashChange();
                  });
                return;
              }
              this.onChangeNominationType(0);
              window.addEventListener('hashchange', this.onHashChange);
              this.onHashChange();
            });
          this.loading = false;
        });
    },
    onBanner() {
      this.$router.push({ name: 'Community' }).catch(() => {});
    },
    isRatingTypeDisabled(index) {
      if (index === 0) {
        if (this.nominationsActive ? this.nominationsActive.total === 0 : true) return true;
      }
      if (index === 1) {
        if (this.isTeamRatingsDisabled()) return true;
        if (this.teamNominationsActive ? this.teamNominationsActive.total === 0 : true) return true;
      }
      return false;
    },
    isTeamRatingsDisabled() {
      return !this.isTeamSet && this.disableTeamsForGuest;
    },
    loadTask(id) {
      this.getTaskGuest(id)
        .then(() => {
          if (this.taskGuest) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = 'available';
            this.taskDetailsExerciseId = id;
            this.taskDetailsOptions = this.taskGuest;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = '';
        });
    },
    loadMyTask(id, peId) {
      this.getTask(peId)
        .then(() => {
          if (this.task) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = this.getTaskDetailsState(this.task);
            this.taskDetailsExerciseId = peId;
            this.taskDetailsOptions = this.task;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = `#!showTask=${id}`;
        });
    },
    processLoadTask(hash) {
      if (hash.length === 2) {
        this.loadTask(hash[1]);
      }
      if (hash.length === 3 && hash[1].indexOf('&peId') >= 0) {
        this.loadMyTask(hash[1].split('&peId')[0], hash[2]);
      }
    },
    getTaskReward(task) {
      let reward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
      }
      return Math.round(reward);
    },
    getTaskRewardRange(task) {
      let reward = 0;
      let maxReward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
        maxReward += task.tasks[i].maxReward;
      }
      return maxReward && reward < maxReward ? `${Math.round(reward)}-${maxReward}` : Math.round(reward);
    },
    getIsFlexibleCost(task) {
      const reward = this.getTaskReward(task);
      if (!task || !task.tasks) return false;
      let maxReward = 0;
      for (let i = 0; i < task.tasks.length; i += 1) {
        maxReward += task.tasks[i].maxReward;
      }
      return reward < maxReward;
    },
    showWelcome() {
      console.log('showWelcome');

      this.welcomeLocalizedText.title = `${this.profile.firstName || ''}, вы участник!`;
      this.welcomeModal = true;
    },
  },

  mounted() {
    this.checkProfile();
    this.setProgressTab();
    this.setBannersBlocks();
    this.initChat();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
    this.removeCanonical();
  },
};
</script>

<style lang="scss" scoped>
  @import "./Club";
</style>
