import api from './api';

const qs = require('qs');

export default {
  getExercisesGuest({
    sortBy, descending, offset, count,
  }) {
    return api.get('/scount/api/v1/exercises', {
      params: {
        sortBy,
        descending,
        offset,
        count,
        actualNow: true,
      },
    });
  },
  getExerciseGuest({ exerciseId }) {
    return api.get(`/scount/api/v1/exercises/${exerciseId}`);
  },
  getExercisePoints({ exerciseId }) {
    return api.get(`/ratings/api/v1/ratings/exercises/${exerciseId}`);
  },
  getExercises({
    state, discipline, sortBy, descending, offset, count,
  }) {
    return api.get('/scount/api/v1/profile/exercises', {
      params: {
        state,
        discipline,
        sortBy,
        descending,
        offset,
        count,
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    });
  },
  assignExercise({ participantExerciseId, exerciseId }) {
    return api.post('/scount/api/v1/profile/exercises', {
      participantExerciseId,
      exerciseId,
    });
  },
  getExercise({ exerciseId }) {
    return api.get(`/scount/api/v1/profile/exercises/${exerciseId}`);
  },
  cancelExercise({ participantExerciseId }) {
    return api.post(`/scount/api/v1/profile/exercises/${participantExerciseId}/cancel`);
  },
  getDisciplines() {
    return api.get('/scount/api/v1/profile/exercises/disciplines');
  },
  getQuestionnaire() {
    return api.get('/scount/api/v1/profile/questionnaire');
  },
  createQuestionnaire() {
    return api.post('/scount/api/v1/profile/questionnaire');
  },
  applyAnswers({ answers }) {
    return api.patch('/scount/api/v1/profile/questionnaire', {
      answers,
    });
  },
  completeQuestionnaire() {
    return api.put('/scount/api/v1/profile/questionnaire/complete');
  },
  getProviders() {
    return api.get('/scount/api/v1/profile/trackers/providers');
  },
  getTrackers() {
    return api.get('/scount/api/v1/profile/trackers');
  },
  getTracker({ trackerId }) {
    return api.get(`/scount/api/v1/profile/trackers/${trackerId}`);
  },
  createTracker({
    trackerId, provider, redirectUrl, parameters, deviceId,
  }) {
    return api.post('/scount/api/v1/profile/trackers', {
      trackerId,
      provider,
      redirectUrl,
      parameters,
      deviceId,
    });
  },
  authTracker({ trackerId, parameters, deviceId }) {
    return api.post(`/scount/api/v1/profile/trackers/${trackerId}/auth`, {
      parameters,
      deviceId,
    });
  },
  disconnectTracker({ trackerId }) {
    return api.put(`/scount/api/v1/profile/trackers/${trackerId}/disconnect`);
  },
  getProfile() {
    return api.get('/scount/api/v1/profile');
  },
  profileBlocked() {
    return api.patch('/scount/api/v1/profile/block');
  },
  setAvatar({ avatarType, file }) {
    const form = new FormData();
    form.append('AvatarType', avatarType);
    if (file) form.append('File', file, 'avatar.jpg');
    return api.post('/scount/api/v1/profile/avatar', form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  checkByEmail({ emailAddress }) {
    return api.get('/scount/api/v1/profile/check-by-email', {
      params: {
        emailAddress,
      },
    });
  },
  getCodeDetails({ code }) {
    return api.get(`/scount/api/v1/codes/${code}`);
  },
  getTeams({ searchString, offset, count }) {
    return api.get('/scount/api/v1/teams', {
      params: {
        searchString,
        offset,
        count,
      },
    });
  },
  getTeamById({ teamId }) {
    return api.get(`/scount/api/v1/teams/${teamId}`);
  },
  getTeamLimit({ teamId }) {
    return api.get(`/scount/api/v1/teams/${teamId}/limit`);
  },
  sendTeamApplication({ application }) {
    const form = new FormData();
    const keys = Object.keys(application);
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === 'logo') form.append('logo', application.logo, 'avatar.jpg');
      else form.append(keys[i], application[keys[i]]);
    }
    return api.post('/scount/api/v1/teams/application', form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  getGoal() {
    return api.get('/scount/api/v1/goal');
  },
  getCalendar() {
    return api.get('/scount/api/v1/calendar');
  },
  sendSupport(application) {
    const form = new FormData();
    const keys = Object.keys(application);
    for (let i = 0; i < keys.length; i += 1) {
      form.append(keys[i], application[keys[i]]);
    }
    return api.post('/scount/api/v1/support', form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  getBalance() {
    return api.get('/scount/api/v1/wallet');
  },
  getBalanceOperations({
    sortBy, descending, offset, count,
  }) {
    return api.get('/scount/api/v1/wallet/operations', {
      params: {
        sortBy,
        descending,
        offset,
        count,
      },
    });
  },
  getRestrictions() {
    return api.get('/scount/api/v1/marketing-programs/restrictions');
  },
  getCode() {
    return api.get('/scount/api/v1/marketing-programs/codes');
  },
  hasDeviceSession({ participantId }) {
    return api.get('/scount/api/v1/profile/devices/active', {
      params: {
        participantId,
      },
    });
  },
  physicalLevel({ participantId, physicalLevel }) {
    return api.patch(`/scount/api/v1/profile/participant/${participantId}/physicalLevel`, {
      physicalLevel,
    });
  },
  sendTrennings({ trainingPlan, answers }) {
    return api.post('/scount-trainings/api/v1/trainings', {
      trainingPlan,
      answers: answers || [],
    });
  },
  sendTrenningsPurchase({ tariffName }) {
    return api.post('/scount-trainings/api/v1/trainings/purchase', {
      tariffName,
    });
  },
  getTrenningPlan() {
    return api.get('/scount-trainings/api/v1/trainings');
  },

};
