/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import stepsApi from '@/services/steps-api';

const state = {
  steps: null,
  stepsHistory: null,
  responseStatus: null,
};

const mutations = {
  setSteps(state, value) {
    state.steps = value;
  },
  setStepsHistory(state, value) {
    state.stepsHistory = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  steps: (state) => state.steps,
  stepsHistory: (state) => state.stepsHistory,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getSteps({ commit }) {
    return stepsApi.getSteps()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setSteps', response.data);
        } else {
          commit('setSteps', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getStepsHistory({ commit }, { dateFrom, dateTo }) {
    return stepsApi.getStepsHistory({ dateFrom, dateTo })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setStepsHistory', response.data);
        } else {
          commit('setStepsHistory', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  setTargetSteps({ commit }, { targetCount }) {
    return stepsApi.setTargetSteps({ targetCount })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
