export default [
  {
    id: 'polar',
    order: 3,
    link: {
      RunningMR: 'https://vk.com/@runningmr-instrukciya-po-ustanovke-trekera-polar',
      VSKGO: 'https://vk.com/@vskgo-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      MGO: 'https://vk.com/@zhit.uchit.bezhat-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      SuperLeague: 'https://vk.com/@runningmr-instrukciya-po-ustanovke-trekera-polar',
      LDPR: 'https://vk.com/@mneposilam-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Shag: 'https://vk.com/@scountlibrary-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Transformation: 'https://vk.com/@zozhtransformacia-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      FarmEco: 'https://vk.com/@-220488887-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Auchan: 'https://vk.com/@auchanvmestelegko-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      SuperLiga78: 'https://vk.com/@superliga78-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon2',
      Clubs50: 'https://vk.com/@zozhcyclone-instrukciya-po-ustanovke-trekera-polar-flow-na-telefon',
      Minsport: 'https://vk.com/@delajrf-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      SportExpo: 'https://vk.com/@-225065954-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      RRExpo: 'https://vk.com/@-226307914-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Severstal: 'https://vk.com/@-226887205-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Demo: 'https://vk.com/@demo.scount-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Sitronics: 'https://vk.com/@-227749883-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
      Lemana: 'https://vk.com/@-228651884-instrukciya-po-ustanovke-trekera-polar-flow-na-vash-telefon',
    },
    ios: 'https://apps.apple.com/ru/app/polar-flow/id717172678',
    android: 'https://play.google.com/store/apps/details?id=fi.polar.polarflow&hl=ru&gl=US',
    huawei: 'https://appgallery.huawei.com/#/app/C103441841',
  },
  {
    id: 'suunto',
    order: 2,
    link: {
      RunningMR: 'https://vk.com/@runningmr-instrukciya-po-ustanovleniu-trekera-suunto-na-vash-telefon',
      VSKGO: 'https://vk.com/@vskgo-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      MGO: 'https://vk.com/@zhit.uchit.bezhat-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      SuperLeague: 'https://vk.com/@runningmr-instrukciya-po-ustanovleniu-trekera-suunto-na-vash-telefon',
      LDPR: 'https://vk.com/@mneposilam-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Shag: 'https://vk.com/@scountlibrary-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Transformation: 'https://vk.com/@zozhtransformacia-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      FarmEco: 'https://vk.com/@-220488887-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Auchan: 'https://vk.com/@auchanvmestelegko-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      SuperLiga78: 'https://vk.com/@superliga78-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon1',
      Clubs50: 'https://vk.com/@zozhcyclone-instrukciya-po-ustanovke-trekera-suunto-na-telefon',
      Minsport: 'https://vk.com/@delajrf-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      SportExpo: 'https://vk.com/@-225065954-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      RRExpo: 'https://vk.com/@-226307914-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Severstal: 'https://vk.com/@-226887205-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Demo: 'https://vk.com/@demo.scount-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Sitronics: 'http://vk.com/@-227749883-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Lemana: 'https://vk.com/@-228651884-nstrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
    },
    ios: 'https://apps.apple.com/ru/app/suunto/id1230327951',
    android: 'https://play.google.com/store/apps/details?id=com.stt.android.suunto&hl=ru&gl=US',
    huawei: '',
  },
  {
    id: 'garmin',
    order: 4,
    link: {
      RunningMR: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      VSKGO: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      MGO: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      SuperLeague: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      LDPR: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      Shag: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      Transformation: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      FarmEco: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      Auchan: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      SuperLiga78: 'https://vk.com/@superliga78-instrukcii-dlya-chasov-i-brasle1tov-garmin',
      Clubs50: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      Minsport: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      SportExpo: 'https://support.garmin.ru/support/solutions/articles/26000030718-Русские-инструкции-для-часов-и-браслетов-garmin',
      RRExpo: 'https://vk.com/@-226307914-instrukcii-dlya-chasov-i-brasletov-garmin',
      Severstal: 'https://vk.com/@-226887205-instrukcii-dlya-chasov-i-brasletov-garmin',
      Demo: 'https://vk.com/@demo.scount-instrukciya-po-ustanovke-trekera-suunto-na-vash-telefon',
      Sitronics: 'https://vk.com/@-227749883-instrukcii-dlya-chasov-i-brasletov-garmin',
      Lemana: 'https://vk.com/@-228651884-instrukcii-dlya-chasov-i-brasletov-garmin',

    },
    ios: 'https://apps.apple.com/ru/app/garmin-connect/id583446403',
    android: 'https://play.google.com/store/apps/details?id=com.garmin.android.apps.connectmobile&hl=ru&gl=US',
    huawei: '',
  },
  {
    id: 'simplerun',
    order: 5,
    link: {
      RunningMR: 'https://vk.com/@runningmr-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      VSKGO: 'https://vk.com/@vskgo-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      MGO: 'https://vk.com/@zhit.uchit.bezhat-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      SuperLeague: 'https://vk.com/@runningmr-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      LDPR: 'https://vk.com/@mneposilam-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      Transformation: 'https://vk.com/@zozhtransformacia-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      FarmEco: 'https://vk.com/@-220488887-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      Auchan: 'https://vk.com/@auchanvmestelegko-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      SuperLiga78: 'https://vk.com/@superliga78-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon1',
      Clubs50: 'https://vk.com/@zozhcyclone-instrukciya-po-ustanovke-trekera-simplerun-na-telefon',
      Minsport: 'https://vk.com/@delajrf-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      SportExpo: 'https://vk.com/@-225065954-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
      Lemana: 'https://vk.com/@-228651884-instrukciya-po-ustanovke-trekera-simplerun-na-vash-telefon',
    },
    ios: 'https://apps.apple.com/ru/app/simple-run/id1475923666?platform=iphone',
    android: 'https://play.google.com/store/apps/details?id=com.simplerun.app&hl=ru&gl=US',
    huawei: '',
  },
  {
    id: 'scounttracker',
    order: 6,
    link: {
      RunningMR: 'https://vk.com/@runningmr-instrukciya-dlya-podklucheniya-trekera-skaunt',
      VSKGO: '#',
      MGO: 'https://vk.com/@zhit.uchit.bezhat-instrukciya-dlya-podklucheniya-trekera-skaunt',
      SuperLeague: 'https://vk.com/@runningmr-instrukciya-dlya-podklucheniya-trekera-skaunt',
      LDPR: 'https://vk.com/@mneposilam-instrukciya-dlya-podklucheniya-trekera-skaunt',
      Shag: '#',
      Transformation: '#',
      FarmEco: '#',
      Auchan: '#',
      SuperLiga78: 'https://vk.com/@superliga78-instrukciya-dlya-podklucheniya-trekera-skaunt',
      Clubs50: '#',
      Minsport: '#',
      SportExpo: 'https://vk.com/@sport__expo-instrukciya-dlya-podklucheniya-trekera-skaunt',
      RRExpo: 'https://vk.com/@rrexpo-instrukciya-dlya-trekera-skaunt',
      Severstal: 'https://vk.com/@-226887205-instrukciya-dlya-trekera-skaunt',
      Demo: 'http://vk.com/@demo.scount-instrukciya-dlya-trekera-skaunt',
      Sitronics: 'https://vk.com/@sitronics_sport-instrukciya-dlya-trekera-skaunt',
      Lemana: 'https://vk.com/@-228651884-instrukciya-dlya-trekera-skaunt',

    },
    ios: 'https://apps.apple.com/ru/app/simple-run/id1475923666?platform=iphone',
    android: 'https://play.google.com/store/apps/details?id=com.simplerun.app&hl=ru&gl=US',
    huawei: '',
  },
  {
    id: 'scounthealth',
    order: 7,
    link: {
      RunningMR: '#',
      VSKGO: '#',
      MGO: '#',
      SuperLeague: '#',
      LDPR: '#',
      Shag: '#',
      Transformation: '#',
      FarmEco: '#',
      Auchan: '#',
      SuperLiga78: '#',
      Clubs50: '#',
      Minsport: '#',
      SportExpo: 'https://vk.com/@sport__expo-instrukciya-po-podklucheniu-trekera-skaunt',
      RRExpo: 'https://vk.com/@rrexpo-instrukciya-podklucheniya-svoego-trekera',
      Severstal: '#',
      Demo: 'https://vk.com/@demo.scount-instrukciya-podklucheniya-svoego-trekera',
      Sitronics: '#',
      Lemana: '#',
    },
    ios: '',
    android: '',
    huawei: '',
  },
];
