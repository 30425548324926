<template>
  <form
    class="form"
    novalidate="novalidate"
    @submit.prevent="submit"
    v-if="isShown"
  >
    <div class="form__step" v-if="showSteps"><span>Шаг {{ currentStep }}</span> из {{ totalSteps }}</div>
    <div class="form__title" v-html="title[currentStep - 1] || ''"></div>
    <div class="form__subtitle" v-if="registerSubtitle && showSteps && currentStep === 2" v-html="registerSubtitle"></div>
    <div class="form__item form__alert" v-if="backendMessage">
      <Alert
        :text="backendMessage"
        state="error"
        no-action
      >
      </Alert>
    </div>
    <div v-show="currentStep === 1" class="form__container">
      <div class="form__item">
        <Field
          label="Электронная почта"
          label-for="email"
          row
        >
          <Input
            v-model="email"
            placeholder="your@email.com"
            id="email"
            name="email"
            type="email"
            class="email"
            :icon-right="!isMobileApp ? 'close-circle-s' : ''"
            disabled
          />
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Имя"
          label-for="name"
          row
        >
          <Input
            v-model.trim="fields.name"
            placeholder="Имя"
            id="name"
            name="name"
            :state="
              showErrors && $v.fields.name.$error ? 'error' : null
            "
            :disabled="sending"
          >
          </Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.name.$error">Введите имя</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Фамилия"
          label-for="surname"
          row
        >
          <Input
            v-model.trim="fields.surname"
            placeholder="Фамилия"
            id="surname"
            name="surname"
            :state="
              showErrors && $v.fields.surname.$error ? 'error' : null
            "
            :disabled="sending"
          >
          </Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.surname.$error">Введите фамилию</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          v-show="$root.isDesktop === true"
          label="Дата рождения"
          label-for="date"
          class="birthday"
          row
        >
          <div class="flex items-center">
            <Input
              v-model="fields.date"
              placeholder="ДД.ММ.ГГГГ"
              v-mask="'##.##.####'"
              id="date"
              name="date"
              autocomplete="off"
              icon-right="calendar"
              noStateIcons
              @change="onDateChange(1)"
              @input="onDateChange(1)"
              :state="
                showErrors && $v.fields.date.$error ? 'error' : null
              "
              :disabled="sending"
            >
            </Input>
          </div>
          <template v-slot:msg v-if="showErrors">
            <div class="color-danger" v-if="!$v.fields.date.required">Укажите дату рождения</div>
            <div class="color-danger" v-else-if="!$v.fields.date.isCorrectLength || !$v.fields.date.isDate">Укажите дату рождения в формате ДД.ММ.ГГГГ</div>
            <div class="color-danger" v-else-if="!$v.fields.date.isAgeValid">Возраст должен быть в пределах от 1 до 100 лет</div>
          </template>
        </Field>
        <Field
          v-show="$root.isDesktop === false"
          label="Дата рождения"
          label-for="date"
          class="birthday"
          row
        >
          <div class="flex items-center">
            <Input
              v-model="fields.dateMobile"
              type="date"
              id="date"
              name="date"
              autocomplete="off"
              noStateIcons
              @change="onDateChange(2)"
              @input="onDateChange(2)"
              :state="
                showErrors && $v.fields.dateMobile.$error ? 'error' : null
              "
              :disabled="sending"
            >
            </Input>
          </div>
          <template v-slot:msg v-if="showErrors">
            <div class="color-danger" v-if="!$v.fields.dateMobile.required">Укажите дату рождения</div>
            <div class="color-danger" v-else-if="!$v.fields.dateMobile.isAgeMobileValid">Возраст должен быть в пределах от 1 до 100 лет</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Пол"
          row
        >
          <div class="gender">
            <div
              v-for="item in genders"
              :key="item.value"
            >
              <Radio
                v-model.number="fields.gender"
                :value="item.value"
                :state="
                  showErrors && $v.fields.gender.$error ? 'error' :
                  null
                "
                :disabled="sending"
              >{{ item.label }}</Radio>
            </div>
          </div>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.gender.$error">Укажите пол</div>
          </template>
        </Field>
      </div>
      <div class="section__group">
        <div class="form__item">
          <div class="divider"></div>
        </div>
        <div class="form__item">
          <Field
            :has-error="showErrors && $v.fields.agreement.$error"
          >
            <Checkbox
              v-model="fields.agreement"
              :disabled="sending"
            >
              <span v-if="!customAgreement">
                С условиями
                <Link
                  text="предоставления услуг"
                  :href="docOferta"
                  variant="primary"
                  target="_blank"
                ></Link>
                ознакомлен и согласен
              </span>
              <span v-else class="custom-agreement" v-html="customAgreement"></span>
            </Checkbox>
          </Field>
        </div>
        <div class="form__item">
          <Row align="center">
            <Col :size="{md: '24'}">
              <Button
                text="Продолжить"
                variant="primary"
                size="lg"
                :loading="sending"
                :disabled="!fields.agreement || hasErrors || sending"
                wide
                mobile
                @click="nextStep(2)"
              ></Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <!-- STEP 2 -->
    <div v-show="currentStep === 2" class="form__container form__container_ordered">
      <div v-show="hasAdditionalField('tel')" class="form__item" :style="{ 'order': getFieldOrder('tel') }">
        <div class="form__label">Номер телефона</div>
        <div class="form__search">
          <Input
            v-model.trim="fields.tel"
            placeholder="+7"
            v-mask="'+7 ### ### ####'"
            id="tel"
            name="tel"
            :state="
              (showErrors && $v.fields.tel.$error) || isTelError ? 'error' :
              null
            "
            :disabled="sending"
            @input="telInput"
          ></Input>
          <div class="error-msg">
            <div class="color-danger" v-if="isTelError">Введите номер телефона</div>
          </div>
        </div>
      </div>
      <div v-show="hasAdditionalField('location')" class="form__item" :style="{ 'order': getFieldOrder('location') }">
        <div class="form__label">Место жительства</div>
        <div class="form__search">
          <Input
            v-model.trim="location"
            ref="searchLocation"
            class="organization"
            size="md"
            :placeholder="locationPlaceholder"
            autocomplete="off"
            :clearable="true && !isLocationSet"
            :state="
              (showErrors && $v.fields.location.$error) || isLocationError ? 'error' :
              (isLocationSet ? 'success' : null)
            "
            :disabled="sending"
            @input="locationInput"
            mobile
          ></Input>
          <div class="error-msg">
            <div class="color-danger" v-if="$v.fields.location.$error">Заполните поле</div>
            <div class="color-danger" v-if="isLocationError && !isLocationNoDistrictError">Укажите населенный пункт</div>
            <div class="color-danger" v-if="isLocationError && isLocationNoDistrictError">Укажите район в этом городе</div>
          </div>
          <Dropdown
            trigger="searchLocation"
            :show="showLocationDropdown"
            auto-width
            hide-overlay
          >
            <template slot="items">
              <div>
                <div
                  class="base-dropdown__item"
                  v-for="(item, i) in locationsValues"
                  :key="i"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent="setLocation(item)"
                  >
                    <div v-html="item.value"></div>
                  </a>
                </div>
                <div
                  v-if="isLocationNotFound"
                  class="base-dropdown__item"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent
                  >
                    <div>Ничего не найдено</div>
                  </a>
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div v-show="hasAdditionalField('occupation')" class="form__item" :style="{ 'order': getFieldOrder('occupation') }">
        <div class="form__label">Укажите ваш род занятий</div>
        <div
          v-for="(item, i) in occupation"
          :key="i"
        >
          <Checkbox
            v-model="fields.occupation[i]"
            :state="
              showErrors && occupationError ? 'error' :
              null
            "
            :disabled="sending"
            @input="onOccupationInput"
          >
            {{ item.label }}
          </Checkbox>
        </div>
        <div class="error-msg">
          <div class="color-danger" v-if="occupationError">Выберите один из вариантов</div>
        </div>
      </div>
      <div
        class="form__item"
        :style="{ 'order': getFieldOrder('organization') }"
        v-show="(!hasAdditionalField('occupation') && hasAdditionalField('organization') && this.fields.union === 'Member') || (hasAdditionalField('occupation') && fields.occupation[1] === true) &&(hasAdditionalField('organization'))"
      >
        <div class="form__label">Место работы</div>
        <div class="form__search">
          <Input
            v-model.trim="organization"
            ref="searchOrganization"
            class="organization"
            size="md"
            placeholder="Начните вводить место работы"
            autocomplete="off"
            :clearable="true && !isOrganizationSet"
            :state="
              showErrors && $v.fields.organization.$error ? 'error' :
              (isOrganizationSet ? 'success' : null)
            "
            :disabled="sending"
            @input="organizationInput"
            mobile
          ></Input>
          <Dropdown
            trigger="searchOrganization"
            :show="showDropdown"
            auto-width
            hide-overlay
          >
            <template slot="items">
              <div>
                <div
                  class="base-dropdown__item"
                  v-for="(item, i) in organizationsValues"
                  :key="i"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent="setOrganization(item)"
                  >
                    <div v-html="item.value"></div>
                    <div><span v-html="item.data.inn"></span>&nbsp;<span v-html="item.data.address.value"></span></div>
                  </a>
                </div>
                <div
                  v-if="isOrganizationNotFound"
                  class="base-dropdown__item"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent
                  >
                    <div>Ничего не найдено</div>
                  </a>
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div v-show="hasAdditionalField('union')" class="form__item" :style="{ 'order': getFieldOrder('union') }">
        <div class="form__label" v-html="registerUnionQuestion"></div>
        <div
          v-for="(item, i) in unionLabels"
          :key="i"
        >
          <Radio
            v-model="fields.union"
            :value="item.value"
            :state="
              showErrors && $v.fields.union.$error ? 'error' :
              null
            "
            :disabled="sending"
          >{{ item.label }}</Radio>
        </div>
      </div>
      <div v-show="hasAdditionalField('ticket') && this.fields.union === 'Member'" class="form__item" :style="{ 'order': getFieldOrder('ticket') }">
        <div class="form__label">Введите номер профсоюзного билета</div>
        <div class="form__search">
          <Input
            v-model.trim="fields.ticket"
            placeholder="16 цифр номера указаны на профсоюзной карте"
            v-mask="'#### #### #### ####'"
            id="ticket"
            name="ticket"
            :state="
              (showErrors && $v.fields.ticket.$error) || isTicketError ? 'error' :
              null
            "
            :disabled="sending"
            @input="ticketInput"
          ></Input>
          <div class="error-msg">
            <div class="color-danger" v-if="isTicketError">Введите номер профсоюзного билета</div>
          </div>
        </div>
      </div>
      <div v-show="hasAdditionalField('position')" class="form__item" :style="{ 'order': getFieldOrder('position') }">
        <div class="form__label">Должность</div>
        <div class="form__search">
          <Input
            v-model.trim="fields.position"
            placeholder="Укажите должность"
            name="position"
            :state="(showErrors && $v.fields.position.$error) ? 'error' : null"
            :disabled="sending"
          ></Input>
          <div class="error-msg">
            <div class="color-danger" v-if="showErrors && $v.fields.position.$error">Укажите вашу должность</div>
          </div>
        </div>
      </div>
      <div v-show="hasAdditionalField('foundOut')" class="form__item" :style="{ 'order': getFieldOrder('foundOut') }">
        <div class="form__label">Откуда узнали о демо-программе</div>
        <div class="form__search">
          <Input
            v-model.trim="fields.foundOut"
            placeholder="Откуда узнали"
            name="position"
            :state="(showErrors && $v.fields.foundOut.$error) ? 'error' : null"
            :disabled="sending"
          ></Input>
          <div class="error-msg">
            <div class="color-danger" v-if="showErrors && $v.fields.foundOut.$error">Укажите откуда узнали о демо-программе</div>
          </div>
        </div>
      </div>
      <div v-show="hasAdditionalField('unionFriend') && this.fields.union === 'NonMember'" class="form__item" :style="{ 'order': getFieldOrder('unionFriend') }">
        <div class="form__label">Являетесь ли вы сторонником партии ЛДПР</div>
        <div
          v-for="(item, i) in unionFriendLabels"
          :key="i"
        >
          <Radio
            v-model="fields.unionFriend"
            :value="item.value"
            :disabled="sending"
          >{{ item.label }}</Radio>
        </div>
      </div>
      <div v-show="hasAdditionalField('region')" class="form__item" :style="{ 'order': getFieldOrder('region') }">
        <div class="form__label">Выбрать регион</div>
          <Select
            :options="regionItems"
            v-model="selectedRegion"
            placeholder="Выберите регион"
            @input="onRegionChange"
            :state="(showErrors && $v.selectedRegion.$error) ? 'error' : null"
            :disabled="sending"
          />
          <div class="error-msg">
            <div class="color-danger" v-if="showErrors && $v.selectedRegion.$error">Выберите регион</div>
          </div>
      </div>
      <div v-show="selectedRegion && hasAdditionalField('division')" class="form__item" :style="{ 'order': getFieldOrder('division') }">
        <div class="form__label">Выбрать подразделение</div>
          <Select
            :options="getDivisionItems(selectedRegion)"
            v-model="fields.divisionId"
            placeholder="Выберите подразделение"
            :state="(showErrors && $v.fields.divisionId.$error) ? 'error' : null"
            :disabled="sending"
          />
          <div class="error-msg">
            <div class="color-danger" v-if="showErrors && $v.fields.divisionId.$error">Выберите подразделение</div>
          </div>
      </div>
      <div v-show="hasAdditionalField('director')" class="form__item" :style="{ 'order': getFieldOrder('director') }">
        <div class="form__label">Являетесь ли вы руководителем</div>
          <div class="gender">
            <div
              v-for="item in directorLabels"
              :key="item.value"
            >
              <Radio
                v-model="fields.director"
                :value="item.value"
                :disabled="sending"
              >{{ item.label }}</Radio>
            </div>
          </div>
        </div>
      <div class="section__group">
        <div class="form__item">
          <div class="divider"></div>
        </div>
        <div class="form__item">
          <Row align="center">
            <Col :size="{md: '24'}">
              <Button
                text="Продолжить"
                variant="primary"
                size="lg"
                type="submit"
                :loading="sending"
                :disabled="!fields.agreement || hasErrors"
                wide
                mobile
              ></Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>

    <Modal
      :active="teamOffLimits"
      name="TeamOffLimitsModal"
      title="Ваша команда уже собрана"
      :subtitle="teamOffLimitsSubtitle"
      icon="info-circle"
      @close.prevent="onTeamOffLimitsClose"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="onTeamOffLimitsClose"
      >
        Понятно
      </Button>
    </Modal>

  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { validationMixin } from 'vuelidate';
import { required, sameAs, requiredIf } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import Debounce from 'lodash/debounce';
import Cookies from 'js-cookie';
import Alert from '@rr-component-library/alert/src/main';
import Field from '@rr-component-library/field/src/main';
import Input from '@rr-component-library/input/src/main';
import Radio from '@rr-component-library/radio/src/main';
import Checkbox from '@rr-component-library/checkbox/src/main';
import Select from '@rr-component-library/select/src/main';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import divisionsList from '@/assets/data/devisions';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const isCorrectLength = (value) => value !== null && value.length === 10;
const isDate = (value) => dayjs(value, 'DD.MM.YYYY', true).isValid() === true;
const isDateMobile = (value) => dayjs(value, 'YYYY-MM-DD', true).isValid() === true;
const isAgeValid = (value) => dayjs(value, 'DD.MM.YYYY', true) >= dayjs().subtract(100, 'year') && dayjs(value, 'DD.MM.YYYY', true) <= dayjs().subtract(1, 'year');
const isAgeMobileValid = (value) => dayjs(value, 'YYYY-MM-DD', true) >= dayjs().subtract(100, 'year') && dayjs(value, 'YYYY-MM-DD', true) <= dayjs().subtract(1, 'year');

export default {
  name: 'PersonalInfo',
  mixins: [validationMixin],
  directives: { mask },
  components: {
    Alert,
    Field,
    Input,
    Radio,
    Checkbox,
    Select,
    Dropdown,
  },
  props: {
    isShown: Boolean,
    isMobileApp: Boolean,
  },
  data() {
    return {
      showErrors: false,
      sending: false,
      backendMessage: null,
      email: '',
      fields: {
        surname: null,
        name: null,
        date: null,
        dateMobile: null,
        gender: null,
        agreement: false,
        occupation: [false, false, false, false, false],
        organization: null,
        location: null,
        tel: null,
        union: null,
        unionFriend: null,
        ticket: null,
        position: null,
        foundOut: null,
        divisionId: null,
        director: 'false',
      },
      directorLabels: [
        {
          label: 'Да',
          value: 'true',
        },
        {
          label: 'Нет',
          value: 'false',
        },
      ],
      genders: [
        {
          label: 'Мужской',
          value: 'Male',
        },
        {
          label: 'Женский',
          value: 'Female',
        },
      ],
      occupation: [
        {
          label: 'Школьник или Студент',
          value: 'student',
        },
        {
          label: 'Трудоустроен',
          value: 'employed',
        },
        {
          label: 'Самозанятый',
          value: 'self-employed',
        },
        {
          label: 'На пенсии',
          value: 'retiree',
        },
        {
          label: 'Временно нетрудоустроен',
          value: 'unemployed',
        },
      ],
      union: {
        MGO: [
          {
            label: 'Да',
            value: 'Member',
          },
          {
            label: 'Нет, я друг/родственник',
            value: 'NonMember',
          },
        ],
        LDPR: [
          {
            label: 'Да',
            value: 'Member',
          },
          {
            label: 'Нет',
            value: 'NonMember',
          },
        ],
      },
      title: [
        'Регистрация участника',
        'Ещё пара вопросов',
      ],
      regionItems: [
        { id: 1, name: 'Регион 0' },
        { id: 2, name: 'Регион 1' },
        { id: 3, name: 'Регион 2' },
        { id: 4, name: 'Регион 3' },
        { id: 5, name: 'Регион 4' },
        { id: 6, name: 'Регион 5' },
        { id: 7, name: 'Регион 6' },
        { id: 8, name: 'Регион 7' },
        { id: 9, name: 'Регион 8' },
        { id: 10, name: 'Регион 9' },
        { id: 11, name: 'Регион 20' },
        { id: 12, name: 'Технический регион' },
      ],
      divisions: divisionsList,
      selectedRegion: null,
      selectedDevision: null,
      showSteps: true,
      currentStep: 1,
      additionalFields: {
        RunningMR: {
          location: { order: 1 }, occupation: { order: 2 }, organization: { order: 3 },
        },
        MGO: {
          tel: { order: 1 }, union: { order: 2 }, ticket: { order: 3 }, organization: { order: 4 },
        },
        SuperLeague: {
          location: { order: 1 }, occupation: { order: 2 }, organization: { order: 3 },
        },
        LDPR: {
          occupation: { order: 1 }, organization: { order: 2, optional: true }, location: { order: 3 }, union: { order: 4 }, unionFriend: { order: 5 },
        },
        Shag: {
          location: { order: 1 }, position: { order: 2 },
        },
        SuperLiga78: {
          occupation: { order: 1 }, organization: { order: 2 }, location: { order: 3 },
        },
        Clubs50: {
          location: { order: 1 }, occupation: { order: 2 }, organization: { order: 3 },
        },
        Minsport: {
          location: { order: 1 },
        },
        SportExpo: {
          location: { order: 1 },
        },
        RRExpo: {
          location: { order: 1 }, occupation: { order: 2 },
        },
        Severstal: {
          location: { order: 1 },
        },
        Demo: {
          location: { order: 1 }, occupation: { order: 2 }, foundOut: { order: 3 },
        },
        Sitronics: {
          location: { order: 1 },
        },
        Lemana: {
          region: { order: 1 }, division: { order: 2 }, director: { order: 3 },
        },
      },
      organization: null,
      organizationsValues: [],
      isOrganizationSet: false,
      isOrganizationNotFound: false,
      location: null,
      locationsValues: [],
      isLocationSet: false,
      isLocationError: false,
      isLocationNoDistrictError: false,
      isLocationNotFound: false,
      debouncedDoSearch: null,
      debouncedDoSearch2: null,
      showDropdown: false,
      showLocationDropdown: false,
      occupationError: false,
      isTelError: false,
      isTicketError: false,
      teamOffLimits: false,
    };
  },
  validations: {
    fields: {
      surname: {
        required,
      },
      name: {
        required,
      },
      date: {
        required: requiredIf(function f() {
          return this.currentStep === 1;
        }),
        isCorrectLength,
        isDate,
        isAgeValid,
      },
      dateMobile: {
        required: requiredIf(function f() {
          return this.currentStep === 1;
        }),
        isDateMobile,
        isAgeMobileValid,
      },
      gender: {
        required,
      },
      agreement: {
        sameAs: sameAs(() => true),
      },
      occupation: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('occupation') && this.currentStep === 2;
        }),
      },
      organization: {
        required: requiredIf(function f() {
          return this.isOrganizationRequired();
        }),
      },
      location: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('location') && this.currentStep === 2;
        }),
      },
      tel: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('tel') && this.currentStep === 2;
        }),
      },
      union: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('union') && this.currentStep === 2;
        }),
      },
      unionFriend: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('unionFriend') && this.fields.union === 'NonMember' && this.currentStep === 2;
        }),
      },
      ticket: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('ticket') && this.fields.union === 'Member' && this.currentStep === 2;
        }),
      },
      position: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('position') && this.currentStep === 2;
        }),
      },
      foundOut: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('foundOut') && this.currentStep === 2;
        }),
      },
      divisionId: {
        required: requiredIf(function f() {
          return this.hasAdditionalField('division') && this.currentStep === 2;
        }),
      },
    },
    selectedRegion: {
      required: requiredIf(function f() {
        return this.hasAdditionalField('region') && this.currentStep === 2;
      }),
    },
  },
  computed: {
    ...mapGetters('settings', ['theme', 'isPrivate', 'options', 'resources']),
    ...mapGetters('user', ['questionnaire', 'questionnaireStatus', 'accessAttributes']),
    ...mapGetters('dadata', ['organizations', 'address', 'responseStatus']),
    hasErrors() {
      return !!(this.showErrors && this.$v.$anyError);
    },
    totalSteps() {
      let steps = 1;
      if (this.showSteps) {
        if (this.showRegisterAdditionalStep) steps += 1;
        if (this.isTeamsEnabled && !this.isTeamStepHidden && !this.isPrivate) steps += 1;
      }
      return steps;
    },
    showRegisterAdditionalStep() {
      return this.options ? this.options.showRegisterAdditionalStep === true : false;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    isTeamStepHidden() {
      return this.options ? this.options.isTeamStepHidden === true || false : false;
    },
    saveCityDistrictForHomeAreaId() {
      return this.options ? this.options.saveCityDistrictForHomeAreaId || '' : '';
    },
    locationsBoost() {
      return this.options ? this.options.locationsBoost || [] : [];
    },
    customAgreement() {
      return this.options ? this.options.customAgreement || '' : '';
    },
    registerSubtitle() {
      return this.resources ? this.resources.registerSubtitle || '' : '';
    },
    registerUnionQuestion() {
      return this.resources ? this.resources.registerUnionQuestion || '' : '';
    },
    docOferta() {
      return this.resources ? this.resources.docOferta || '#' : '#';
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    supportEmailText() {
      return this.resources ? this.resources.supportEmailText || '' : '';
    },
    currentAdditionalFields() {
      return this.additionalFields[this.theme] ? this.additionalFields[this.theme] : {};
    },
    locationPlaceholder() {
      return this.resources ? this.resources.locationPlaceholder || 'Начните вводить название населенного пункта' : '';
    },
    unionLabels() {
      if (!this.union[this.theme]) {
        return [
          {
            label: 'Да',
            value: 'Member',
          },
          {
            label: 'Нет',
            value: 'NonMember',
          },
        ];
      }
      return this.union[this.theme];
    },
    unionFriendLabels() {
      return [
        {
          label: 'Да',
          value: 'Friend',
        },
        {
          label: 'Нет',
          value: 'NonFriend',
        },
      ];
    },
    unionFriendIsMember() {
      const values = { LDPR: true };
      return values[this.theme] === true || false;
    },
    teamOffLimitsSubtitle() {
      return `В вашей команде больше нет свободных слотов для участия. Попросите организатора в вашей компании связаться с нами<br/>
      <a href="mailto:${this.supportEmail}">${this.supportEmailText || this.supportEmail}</a>`;
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    isClubEnabled() {
      return this.options ? this.options.isClubEnabled === true : false;
    },
  },
  watch: {
    isShown(val) {
      if (val) {
        this.showErrors = false;
        this.sending = false;
      }
    },
  },
  methods: {
    ...mapActions('user', ['getQuestionnaire', 'createQuestionnaire', 'applyAnswers', 'completeQuestionnaire', 'logout']),
    ...mapActions('dadata', ['searchOrganization', 'searchAddress']),
    onRegionChange() {
      // Сбросить выбор подразделения при изменении региона
      this.fields.divisionId = null;
    },
    getDivisionItems(regionId) {
      // Фильтруем подразделения по regionId
      return this.divisions.filter((division) => division.regionId === regionId);
    },
    setClearHandlers() {
      const iconEmail = document.querySelector('.email .rr-input__icons .rr-input__icon:last-child');
      if (iconEmail) {
        iconEmail.addEventListener('click', this.resetEmail);
      }
      const iconOrganization = document.querySelector('.organization .rr-input__icons .rr-input__icon:last-child');
      if (iconOrganization) {
        iconOrganization.addEventListener('click', this.resetOrganization);
      }
    },
    checkQuestionnaire() {
      this.getQuestionnaire()
        .then(() => {
          if (this.questionnaireStatus === 200) {
            if (this.questionnaire.state === 'Created') {
              this.setData();
            } else if (this.questionnaire.state === 'Completed') {
              this.setData();
              this.sending = true;
              setTimeout(() => { this.checkQuestionnaire(); }, 2000);
            } else if (this.questionnaire.state === 'Approved') {
              if (this.isMobileApp) {
                this.$router.push({ name: 'RegisterComplete' }).catch(() => {});
                return;
              }
              if (this.isClubEnabled) {
                this.$router.push({ name: 'Club', query: { isFirstLogin: 'true' } }).catch(() => {});
                this.sending = false;
                return;
              }
              if (this.isTeamsEnabled && this.isPrivate) {
                this.$router.push({ name: 'Dashboard', query: { isFirstLogin: 'true' } }).catch(() => {});
              } else if (this.isNeedToSelectTeam()) {
                this.$router.push({ name: 'Team' }).catch(() => {});
              } else {
                this.$router.push({ name: 'Dashboard', query: { isFirstLogin: 'true' } }).catch(() => {});
              }
              this.sending = false;
            }
          } else if (this.questionnaireStatus === 404) {
            this.createQuestionnaire()
              .then(() => {
                if (this.questionnaireStatus === 201) {
                  this.getQuestionnaire()
                    .then(() => {
                      this.setData();
                    });
                }
              });
          } else if (this.questionnaireStatus === 401) {
            this.$router.push({ name: 'Auth' }).catch(() => {});
          }
        });
    },
    setData() {
      const fromSSO = sessionStorage.getItem('authFromSSO') === 'true';
      if (fromSSO) {
        let accessAttributes = [...this.accessAttributes];
        if (accessAttributes.length === 0) {
          try {
            accessAttributes = sessionStorage.getItem('ssoAccessAttributes');
            if (accessAttributes) accessAttributes = JSON.parse(accessAttributes);
          } catch (e) {
            //
          }
        }
        if (accessAttributes.length > 0) {
          sessionStorage.setItem('ssoAccessAttributes', JSON.stringify(accessAttributes));
          this.email = this.getAnswerByKey('EmailAddress').toLowerCase();
          this.fields.surname = this.getAttributeByKey(accessAttributes, 'LastName') || null;
          this.fields.name = this.getAttributeByKey(accessAttributes, 'FirstName') || null;
          this.fields.date = this.convertDateRR(this.getAttributeByKey(accessAttributes, 'BirthDate')) || null;
          this.fields.dateMobile = dayjs(this.fields.date, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
          this.fields.gender = this.getAttributeByKey(accessAttributes, 'Gender') || null;
          return;
        }
      }

      this.email = this.getAnswerByKey('EmailAddress');
      this.fields.surname = this.getAnswerByKey('LastName') || null;
      this.fields.name = this.getAnswerByKey('FirstName') || null;
      this.fields.date = this.convertDate(this.getAnswerByKey('Birthday')) || null;
      this.fields.dateMobile = dayjs(this.fields.date, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
      this.fields.gender = this.getAnswerByKey('Gender') || null;
      if (this.questionnaire.state === 'Completed' || this.questionnaire.state === 'Approved') {
        this.fields.agreement = true;
      }
    },
    getData() {
      const { fields } = this;
      const result = {
        LastName: fields.surname,
        FirstName: fields.name,
        Birthday: this.$root.isDesktop === true ? this.formatDate(fields.date) : fields.dateMobile,
        Gender: fields.gender,
      };
      if (this.isPrivate) {
        const invitationTeamId = sessionStorage.getItem('invitationTeamId');
        if (invitationTeamId) result.TeamId = invitationTeamId;
      }
      if (this.showRegisterAdditionalStep) {
        if (this.hasAdditionalField('occupation')) {
          result.IsStudent = fields.occupation[0].toString();
          result.IsEmployed = fields.occupation[1].toString();
          result.IsSelfEmployed = fields.occupation[2].toString();
          result.IsRetiree = fields.occupation[3].toString();
          result.IsUnemployed = fields.occupation[4].toString();
        }
        if (this.hasAdditionalField('location')) {
          result.HomeRegionId = fields.location.data.region_fias_id;
          result.HomeRegionName = fields.location.data.region;
          result.HomeAreaId = fields.location.data.area_fias_id || fields.location.data.city_fias_id || fields.location.data.settlement_fias_id;
          result.HomeAreaName = fields.location.data.area || fields.location.data.city || fields.location.data.settlement;
          result.HomeFull = fields.location.value;
          if (this.saveCityDistrictForHomeAreaId && this.saveCityDistrictForHomeAreaId === result.HomeAreaId) {
            result.CityDistrictName = fields.location.data.city_district;
          }
        }

        if ((this.hasAdditionalField('organization') && fields.union === 'Member' && fields.occupation[1] === true)
          || (this.hasAdditionalFields(['occupation', 'organization']) && fields.occupation[1] === true)
          || (this.hasAdditionalField('organization') && fields.union === 'Member' && !this.hasAdditionalField('occupation'))) {
          const optional = this.isFieldOptional('organization');
          if (optional !== true) {
            result.OrganizationId = fields.organization.data.inn;
            result.OrganizationName = fields.organization.value;
            result.OrganizationRegionId = fields.organization.data.address.data.region_fias_id;
            result.OrganizationRegionName = fields.organization.data.address.data.region;
            result.OrganizationAreaId = fields.organization.data.address.data.area_fias_id || fields.organization.data.address.data.city_fias_id;
            result.OrganizationAreaName = fields.organization.data.address.data.area || fields.organization.data.address.data.city;
            result.OrganizationAddress = fields.organization.data.address.value;
            result.OrganizationOkved = fields.organization.data.okved;
          }
        }

        if (this.hasAdditionalField('tel')) {
          result.Tel = fields.tel.replaceAll(' ', '');
        }
        if (this.hasAdditionalField('union')) {
          result.IsUnionMember = (fields.union === 'Member').toString();
        }
        if (this.hasAdditionalField('unionFriend')) {
          result.IsUnionFriend = (fields.unionFriend === 'Friend').toString();
          if (this.unionFriendIsMember && fields.unionFriend === 'Friend') {
            result.IsUnionMember = 'true';
          }
        }
        if (this.hasAdditionalField('ticket') && fields.union === 'Member') {
          result.UnionMemberTicket = fields.ticket.replaceAll(' ', '');
        }
        if (this.hasAdditionalField('position')) {
          result.Position = fields.position;
        }
        if (this.hasAdditionalField('foundOut')) {
          result.FoundOut = fields.foundOut;
        }
        if (this.hasAdditionalField('division')) {
          result.DivisionId = String(this.fields.divisionId);
        }
        if (this.hasAdditionalField('director')) {
          result.Director = fields.director;
        }
      }
      return result;
    },
    autoJoinTeam() {
      const invitationTeamId = sessionStorage.getItem('invitationTeamId');
      const teamId = this.questionnaire.answers.find((i) => i.key === 'TeamId');
      if (teamId || !invitationTeamId) {
        this.checkQuestionnaire();
        return;
      }

      this.joinTeam(invitationTeamId);
    },
    joinTeam(teamId) {
      const data = { TeamId: teamId };
      this.applyAnswers(data)
        .then(() => {
          if (this.questionnaireStatus === 204) {
            this.checkQuestionnaire();
          }
        });
    },
    getAnswerByKey(key) {
      if (!this.questionnaire.answers || !key) return '';
      const found = this.questionnaire.answers.filter((i) => i.key === key);
      if (found.length === 0) return '';
      return found[0].value;
    },
    getAttributeByKey(attributes, key) {
      if (!attributes || !key) return '';
      const found = attributes.filter((i) => i.key === key);
      if (found.length === 0) return '';
      return found[0].value;
    },
    formatDate(date) {
      return `${date.slice(6)}-${date.slice(3, 5)}-${date.slice(0, 2)}`;
    },
    convertDate(date) {
      if (!date) return '';
      return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
    },
    convertDateRR(date) {
      if (!date) return '';
      return `${date.slice(3, 5)}.${date.slice(0, 2)}.${date.slice(6, 10)}`;
    },
    nextStep(step) {
      this.showErrors = true;
      this.$v.$touch();
      if (this.hasErrors) {
        return;
      }
      this.showErrors = false;
      if (this.showRegisterAdditionalStep) {
        this.organizationsValues = [];
        this.isOrganizationSet = false;
        this.isOrganizationNotFound = false;
        this.showDropdown = false;
        this.showLocationDropdown = false;
        this.currentStep = step;
      } else {
        this.submit();
      }
    },
    onDateChange(type) {
      if (type === 1) {
        if (this.fields.date && this.fields.date.length === 10) {
          this.fields.dateMobile = dayjs(this.fields.date, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
        }
      }
      if (type === 2) {
        if (this.fields.dateMobile && this.fields.dateMobile.length === 10) {
          this.fields.date = dayjs(this.fields.dateMobile, 'YYYY-MM-DD', true).format('DD.MM.YYYY');
        }
      }
    },
    onTeamOffLimitsClose() {
      this.teamOffLimits = false;
      this.logout();
    },
    organizationInput() {
      this.isOrganizationSet = false;
      this.isOrganizationNotFound = false;
      if (this.organization !== '') {
        this.debouncedDoSearch();
      } else {
        this.organizationsValues = [];
        this.isOrganizationNotFound = false;
        this.showDropdown = false;
      }
    },
    locationInput() {
      this.isLocationSet = false;
      this.isLocationError = false;
      this.isLocationNoDistrictError = false;
      this.isLocationNotFound = false;
      if (this.location !== '') {
        this.debouncedDoSearch2();
      } else {
        this.locationsValues = [];
        this.isLocationNotFound = false;
        this.showLocationDropdown = false;
      }
    },
    telInput() {
      this.isTelError = false;
    },
    ticketInput() {
      this.isTicketError = false;
    },
    doSearchOrganization() {
      if (this.organization === '') return;
      this.backendMessage = null;
      this.searchOrganization(this.organization)
        .then(() => {
          if (this.responseStatus === 200) {
            this.organizationsValues = this.organizations.suggestions;
            this.isOrganizationNotFound = this.organizations.suggestions.length === 0;
            this.showDropdown = true;
            return;
          }
          this.backendMessage = `Произошла ошибка: ${this.responseStatus}`;
          this.organizationsValues = [];
          this.isOrganizationSet = false;
          this.isOrganizationNotFound = false;
          this.showDropdown = false;
        });
    },
    doSearchLocation() {
      if (this.location === '') return;
      this.backendMessage = null;
      this.searchAddress({ query: this.location, locationsBoost: this.locationsBoost })
        .then(() => {
          if (this.responseStatus === 200) {
            this.locationsValues = this.address.suggestions;
            this.isLocationNotFound = this.locationsValues.length === 0;
            this.showLocationDropdown = true;
            return;
          }
          this.backendMessage = `Произошла ошибка: ${this.responseStatus}`;
          this.locationsValues = [];
          this.isLocationSet = false;
          this.isLocationNotFound = false;
          this.showLocationDropdown = false;
        });
    },
    setOrganization(item) {
      this.fields.organization = item;
      this.organization = item.value;
      this.isOrganizationSet = true;
      this.isOrganizationNotFound = false;
      this.showDropdown = false;
    },
    setLocation(item) {
      const id = item.data.area_fias_id || item.data.city_fias_id || item.data.settlement_fias_id;
      if (id === null) {
        this.fields.location = null;
        this.location = '';
        this.isLocationSet = false;
        this.isLocationError = true;
        this.isLocationNoDistrictError = false;
        this.isLocationNotFound = false;
        this.showLocationDropdown = false;
        return;
      }
      if (this.saveCityDistrictForHomeAreaId && this.saveCityDistrictForHomeAreaId === id) {
        if (!item.data.city_district) {
          this.fields.location = null;
          this.location = item.value;
          this.isLocationSet = false;
          this.isLocationError = true;
          this.isLocationNoDistrictError = true;
          this.isLocationNotFound = false;
          this.showLocationDropdown = false;
          return;
        }
      }
      this.fields.location = item;
      this.location = item.value;
      this.isLocationSet = true;
      this.isLocationError = false;
      this.isLocationNoDistrictError = false;
      this.isLocationNotFound = false;
      this.showLocationDropdown = false;
    },
    onOccupationInput() {
      this.occupationError = false;
    },
    submit() {
      if (this.sending) {
        return;
      }
      this.showErrors = true;
      this.occupationError = false;
      this.isTelError = false;
      this.isTicketError = false;
      if (this.showRegisterAdditionalStep) {
        if (!this.checkOccupation()) this.occupationError = true;
        if (!this.checkTel()) this.isTelError = true;
        if (!this.checkTicket()) this.isTicketError = true;
      }
      this.$v.$touch();
      if (this.hasErrors || this.occupationError || this.isTelError || this.isTicketError) {
        return;
      }
      this.sending = true;
      this.backendMessage = null;
      this.applyAnswers(this.getData())
        .then(() => {
          if (this.questionnaireStatus === 204) {
            this.completeQuestionnaire()
              .then(() => {
                if (this.questionnaireStatus === 204) {
                  if (!this.isPrivate) this.autoJoinTeam();
                  else this.checkQuestionnaire();
                } else if (this.questionnaireStatus === 409) {
                  this.teamOffLimits = true;
                } else {
                  this.sending = false;
                  this.showErrors = false;
                  this.occupationError = false;
                  this.backendMessage = `Произошла ошибка: ${this.questionnaireStatus}`;
                }
              });
          } else {
            this.sending = false;
            this.showErrors = false;
            this.occupationError = false;
            this.backendMessage = `Произошла ошибка: ${this.questionnaireStatus}`;
          }
        });
    },
    checkOccupation() {
      if (!this.hasAdditionalField('occupation')) return true;
      return this.fields.occupation.filter((i) => i === true).length > 0;
    },
    checkTel() {
      if (!this.hasAdditionalField('tel')) return true;
      const { tel } = this.fields;
      if (tel === null || tel === '') return false;
      const value = tel.replaceAll(' ', '');
      return value.length === 12;
    },
    checkTicket() {
      if (!this.hasAdditionalField('ticket') || !this.hasAdditionalField('union')) return true;
      if (this.fields.union === 'NonMember') return true;
      const { ticket } = this.fields;
      if (ticket === null || ticket === '') return false;
      const code = ticket.replaceAll(' ', '');
      return code.length === 16 && code.indexOf('96438090') === 0;
    },
    hasAdditionalField(fieldName) {
      return this.currentAdditionalFields[fieldName] !== undefined;
    },
    hasAdditionalFields(fields) {
      if (!fields) return false;
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.currentAdditionalFields[fields[i]]) return false;
      }
      return true;
    },
    getFieldOrder(fieldName) {
      const field = this.currentAdditionalFields[fieldName];
      if (!field) return 'unset';
      return field.order || 'unset';
    },
    isFieldOptional(fieldName) {
      const field = this.currentAdditionalFields[fieldName];
      if (!field) return null;
      return field.optional === true;
    },
    isOrganizationRequired() {
      const optional = this.isFieldOptional('organization');
      if (optional === true) return false;
      if (this.hasAdditionalField('occupation') && !this.hasAdditionalField('organization')) return false;
      return (!this.hasAdditionalField('occupation') && this.hasAdditionalField('organization') && this.fields.union === 'Member' && this.currentStep === 2)
        || (this.hasAdditionalField('occupation') && this.fields.occupation[1] === true && this.currentStep === 2);
    },
    resetEmail() {
      Cookies.remove('token');
      sessionStorage.removeItem('authFromSSO');
      sessionStorage.removeItem('ssoAccessAttributes');
      this.$router.push({ name: 'Auth' }).catch(() => {});
    },
    resetOrganization() {
      this.organizationsValues = [];
    },
    isNeedToSelectTeam() {
      return this.isTeamsEnabled && !this.isTeamStepHidden && (!this.hasAdditionalField('union')
        || (this.hasAdditionalField('union') && this.fields.union === 'Member')
        || (this.unionFriendIsMember && this.hasAdditionalField('unionFriend') && this.fields.unionFriend === 'Friend'));
    },
  },
  mounted() {
    this.setClearHandlers();
    this.checkQuestionnaire();
    this.debouncedDoSearch = Debounce(this.doSearchOrganization, 500);
    this.debouncedDoSearch2 = Debounce(this.doSearchLocation, 500);
  },
};
</script>

<style lang="scss" scoped>
  @import "./PersonalInfo";
</style>
