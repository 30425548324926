<template>
  <div class="index-ofp">
    <div class="index-ofp__progress-container">
      <div class="index-ofp__progress">
        <ProgressBarSteps :value="value" :targetSteps="targetSteps" />
      </div>
      <div class="index-ofp__value">
        {{ value }}
      </div>
    </div>
    <div>
      <div class="index-ofp__target">из {{ targetSteps }}</div>
      <div class="index-ofp__date" v-html="dates"></div>
    </div>
  </div>
</template>

<script>
import ProgressBarSteps from '@/components/ProgressBarCircle/ProgressBarSteps.vue';

export default {
  name: 'IndexSteps',
  components: {
    ProgressBarSteps,
  },
  props: {
    value: Number,
    targetSteps: Number,
    simple: Boolean,
    dates: String,
  },
};
</script>

<style lang="scss" scoped>
  @import "./IndexOFP";
</style>
