import api from './api';

export default {
  getSteps() {
    return api.get('/scount-steps/api/v1/steps');
  },
  setTargetSteps({ targetCount }) {
    return api.post('/scount-steps/api/v1/targets', {
      targetCount,
    });
  },
  getStepsHistory({ dateFrom, dateTo }) {
    return api.get('/scount-steps/api/v1/participants/steps-with-targets', {
      params: {
        dateFrom,
        dateTo,
      },
    });
  },
};
