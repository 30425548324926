<template>
  <div class="profile-experience">
    <div v-if="!$root.isDesktop" class="profile-experience__name-avatar">
      <div class="profile-experience__name">
        <div class="name__greeting">Привет,</div>
        <div class="name__firstname" v-html="firstName || ''" :title="firstName || ''"></div>
      </div>
      <div class="profile-experience__avatar">
        <div class="avatar" :class="{ 'avatar_pointer': hideEdit }" @click.stop="onSettings">
          <div class="avatar__image" :style="{ 'background-image': `url(${avatar})` }" @click.stop="onSettings"></div>
          <div v-if="!hideEdit" class="avatar__edit" @click.stop="onEditAvatar">
            <Icon name="edit" size="m" />
          </div>
        </div>
      </div>
    </div>
    <div class="profile-experience__items" :class="{ 'no-steps' : !isSpecialSettingsMp}">
    <div class="profile-experience__xp-achievements">
      <div class="xp-achievements__xp">
        <div class="xp__content">
          <div class="xp__labels">
            <div class="xp__level">{{ currentLevel }} ур.</div>
            <div class="xp__values"><span>{{ currentValue - currentLevelStartValue }}</span><span> / {{ nextLevelValue - currentLevelStartValue }} XP</span></div>
          </div>
          <div class="xp__bar">
            <div :style="{ 'width': `${ getPercent() }%` }"></div>
          </div>
        </div>
        <div class="xp__question">
          <Button
            id="showLevelInfo"
            variant="secondary"
            shape="circle"
            size="sm"
            iconLeft="question"
          ></Button>
          <Dropdown
            triggerId="showLevelInfo"
            :placement="$root.isDesktop ? 'top-end' : 'top'"
            listeners="click"
            hide-overlay
            auto-width
          >
            <template slot="items">
              <LevelInfo
                :level="currentLevel"
                :needXP="needXP"
              />
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="xp-achievements__achievements">
        <div
          v-for="item in activeAchievements"
          :key="item.achievementId"
          class="achievements__item"
          :class="getActiveClass(item)"
          @click.stop="onSelected(item.achievementType)"
        ></div>
        <div
          v-for="(item, i) in grayAchievements"
          :key="i"
          class="achievements__item"
          :class="getGrayClass(item)"
          @click.stop="onSelected(item.achievementType)"
        >
          <img :src="item.img" />
        </div>
        <div
          v-if="countMore > 0"
          class="achievements__item"
          @click.stop="onSelected('Welcome')"
        >
          <img :src="imageMore" />
          <div class="item__more">+{{ countMore }}</div>
        </div>
      </div>
    </div>
    <div class="profile-experience__item" @click="onShowStepsModal" v-if="isSpecialSettingsMp">
      <div class="profile-experience__steps">
        <div class="profile-experience__steps-top">
          <IndexSteps
            :value="steps ? steps.stepsCount : 0"
            :targetSteps="steps ? steps.target : 0"
            @show="onShowIndexOFP"
          />
        </div>
        <p>{{pluralizeSteps(steps ? steps.stepsCount : 0)}} сегодня</p>
      </div>
    </div>
    <div @click="onShowIndexOFP" class="profile-experience__item">
      <IndexOFP
        v-if="sportIndexes"
        :value="sportIndexes.index"
        :dates="formatDateRange({ start: sportIndexes.startDate, finish: sportIndexes.finishDate })"
        @show="onShowIndexOFP"
      />
    </div>
    </div>
    <Modal
      :active="achievementsModal"
      name="AchievementsModal"
      title="Мои достижения"
      :subtitle="`Получено ${ achievements.length } / ${ achievementsAll.length }`"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="achievementsModal = false"
    >
      <div class="modal-content text-left">
        <div
          v-for="cat in categories"
          :key="cat.id"
          class="modal-content__group"
          :class="{ 'd-none': getCountOfCat(cat.id) === 0 }"
        >
          <div class="modal-content__group-title" @click.stop="!$root.isDesktop ? setCollapsed(cat.id) : null">
            <div class="group-title__name">
              <div class="group-title__text" v-html="cat.name || ''"></div>
              <div class="group-title__count">Получено {{ getActiveOfCatCount(cat.id) }} / {{ getCountOfCat(cat.id) }}</div>
            </div>
            <div class="group-title__icon" @click.stop="setCollapsed(cat.id)">
              <Icon v-if="cat.collapsed" name="down" size="xl" />
              <Icon v-else name="up" size="xl" />
            </div>
          </div>
          <div v-if="!cat.collapsed" class="modal-content__group-details">
            <div
              v-for="item in getAchievementsOfCat(cat.id)"
              :key="item.achievementType"
              class="group-details__achievement text-center"
            >
              <div
                class="group-details__image"
                :class="getActiveClass(item, true)"
                @click.stop="onAchievementDetails(item)"
              ></div>
              <div class="group-details__title" v-html="item.name || ''" @click.stop="onAchievementDetails(item)"></div>
              <div class="group-details__description" v-html="item.desc || ''"></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      :active="descriptionModal"
      name="AchievementDescriptionModal"
      class="achievement__description"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="descriptionModal = false"
    >
      <div class="achievement__description-img" :class="descriptionImageClass"></div>
      <div class="achievement__description-title" v-html="descriptionTitle"></div>
      <div class="achievement__description-subtitle" v-html="descriptionSubtitle"></div>
      <Button
        text="Назад к достижениям"
        variant="secondary"
        size="lg"
        wide
        mobile
        @click="descriptionModal = false"
      ></Button>
    </Modal>

    <Modal
      :active="stepsModal"
      :img="walkingAroundRafiki"
      title="Мои шаги"
      name="stepsModal"
      class="index-ofp modal-steps"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="stepsModal = false"
    >
      <div class="ofp">
        <div class="ofp__value">
          <Slider
            v-if="stepsModal && stepsHistory.length > 0"
            :initialSlide="initialStepsSlide"
            :navigationOffset="navigationOffset1"
            :noPagination="noPagination1"
            class="ofp__slider"
          >
            <IndexSteps
              v-for="(item, i) in stepsHistory"
              :key="i"
              :value="item.stepsCount"
              :targetSteps="item.targetCount"
              :dates="formatStepsDate(item.date)"
              :simple="true"
            />
          </Slider>
        </div>
        <div class="ofp__description" v-html="stepsDescription"></div>
        <Link @click="onTargetSteps" text="Изменить цель шагов" iconLeft="empty-flag"/>
      </div>
    </Modal>

    <Modal
      :active="indexOFPModal"
      :img="ofpImage"
      :title="ofpTitle"
      :subtitle="ofpSubtitle"
      name="IndexOFPModal"
      class="index-ofp"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="indexOFPModal = false"
    >
      <div class="ofp">
        <div class="ofp__value">
          <Slider
            v-if="indexOFPModal && ofpValues.length > 0"
            :initialSlide="initialSlide"
            :navigationOffset="navigationOffset"
            :noPagination="noPagination"
            class="ofp__slider"
          >
            <IndexOFP
              v-for="(item, i) in ofpValues"
              :key="i"
              :value="ofpValues[i].value"
              :dates="ofpValues[i].dates"
              :simple="true"
            />
          </Slider>
        </div>
        <div class="ofp__description" v-html="ofpDescription"></div>
        <Button
          class="ofp-more"
          text="Узнать больше"
          variant="secondary"
          tag="a"
          :target="!isMobileApp ? '_blank' : ''"
          :href="articleAboutIndexOFP"
          size="lg"
          mobile
        ></Button>
      </div>
    </Modal>

    <Modal
      :active="guideModal"
      name="guideModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="guideModal = false"
    >
    <div class="guide__title guide__title_steps" v-html="guideTitle"></div>
    <div v-if="$root.isMobile" class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 1.</b> Скопируйте код для входа в спортивную программу
      <div class="guide__passcode guide__passcode_color">
        <span>{{ code ? code.code : '' }}</span>
        <span @click.stop="onCopyCode"><Icon name="copy" /></span>
      </div>
    </div>
    <div v-if="!$root.isMobile" class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 1.</b> Установите приложение
      <div class="guide__platform-link">
      <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'unknown'" target="_blank"  href="https://play.google.com/store/apps/details?id=pro.scount.app&pli=1" class="modal-qrcode__item">
        <img class="modal-qrcode__item-img" :src="googleplayImg" alt="" />
      </a>
      <a v-if="OS === 'unknown'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    <div class="guide__platform-link">
      <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'Android'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    </div>
    <div v-if="$root.isMobile" class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 2.</b> Установите приложение и войдите с помощью кода из шага 1
      <div class="guide__platform-link">
      <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'unknown'" target="_blank"  href="https://play.google.com/store/apps/details?id=pro.scount.app&pli=1" class="modal-qrcode__item">
        <img class="modal-qrcode__item-img" :src="googleplayImg" alt="" />
      </a>
      <a v-if="OS === 'unknown'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    <div class="guide__platform-link">
      <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'Android'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
      <a v-if="OS === 'Android'" target="_blank"  href="https://play.google.com/store/apps/details?id=pro.scount.app&pli=1" class="modal-qrcode__item">
        <img class="modal-qrcode__item-img" :src="googleplayImg" alt="" />
      </a>
    </div>
    </div>
    <!-- <div v-if="android || iOS" class="guide__platform-link">
      <a :href="android ? appAndroid : appiOS" target="_blank"><img :src="android ? googlePlayImg : appStoreImg"/></a>
    </div> -->
    <div v-if="!$root.isMobile" class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 2.</b> Введите код спортивной программы в приложении
      <div class="guide__passcode guide__passcode_color">
        <span>{{ code ? code.code : '' }}</span>
        <span @click.stop="onCopyCode"><Icon name="copy" /></span>
      </div>
    </div>
    <div class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 3.</b> Разрешите приложению доступ к данным физической активности
      <div>
        <span v-if="OS === 'unknown'"><img :src="healthConnectImg"/></span>
        <span v-if="OS === 'unknown'"><img :src="healthImg"/></span>
        <span v-if="OS === 'Android'"><img :src="healthConnectImg"/></span>
        <span v-if="OS === 'iOS'"><img :src="healthImg"/></span>
      </div>
    </div>
    <div class="guide__footer">
      Ходите и получайте <br> <b>по 2 <img :src="coinImg"/> за каждую 1 000 шагов</b>
    </div>
    <div class="sneak-bar" v-if="showCopied">
      <Alert
        text="Код скопирован."
        actionText="Понятно"
        state="success"
        closable
        autoClose
        @close="showCopied = false"
      ></Alert>
    </div>
    </Modal>

    <SetAvatarModal
      :active="showSetAvatar"
      :isMobileApp="isMobileApp"
      class="set-avatar"
      @close="showSetAvatar = false"
    ></SetAvatarModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import SetAvatarModal from '@/components/SetAvatarModal/SetAvatarModal.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import LevelInfo from '@/components/LevelInfo/LevelInfo.vue';
import IndexOFP from '@/components/IndexOFP/IndexOFP.vue';
import IndexSteps from '@/components/IndexOFP/IndexSteps.vue';
import AchievementsAll from '@/assets/data/achievements';
import WHOImage from '@/assets/img/index-ofp/who.svg';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import RuStoreImg from '@/assets/img/connection-guide/rustore.svg';
import HealthImg from '@/assets/img/connection-guide/health.svg';
import HealthConnectImg from '@/assets/img/connection-guide/health-connect-app.svg';
import CoinImg from '@/assets/img/coin.svg';
import ScountImgMiddle from '@/assets/img/scount-middle.svg';
import { formatDateRange, addNonBreakingSpaces } from '@/assets/js/helpers';

dayjs.extend(weekday);

const DIR = '/img/achievements';

export default {
  name: 'ProfileExperience',
  props: {
    firstName: String,
    avatar: String,
    experience: Object,
    achievements: Array,
    sportIndexes: Object,
    hideEdit: Boolean,
    isMobileApp: Boolean,
    steps: Object,
  },
  components: {
    SetAvatarModal,
    Dropdown,
    LevelInfo,
    IndexOFP,
    IndexSteps,
    Alert,
  },
  data() {
    return {
      achievementsAll: AchievementsAll,
      categories: [
        { id: 'common', name: 'Общие', collapsed: false },
        { id: 'walking', name: 'Ходьба', collapsed: true },
        { id: 'running', name: 'Бег', collapsed: true },
        { id: 'cycling', name: 'Велозаезд', collapsed: true },
        { id: 'swimming', name: 'Плавание', collapsed: true },
        { id: 'skiing', name: 'Лыжи', collapsed: true },
        { id: 'market', name: 'Маркет', collapsed: true },
      ],
      imageMore: `${DIR}/system-empty.svg`,
      countMore: 0,
      activeAchievements: [],
      grayAchievements: [],
      maxMobileItems: 7,
      maxDesktopItems: 7,
      achievementsModal: false,
      descriptionModal: false,
      descriptionImageClass: '',
      descriptionTitle: '',
      descriptionSubtitle: '',
      showSetAvatar: false,
      indexOFPModal: false,
      stepsModal: false,
      ofpImage: WHOImage,
      googleplayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      rustoreImg: RuStoreImg,
      healthImg: HealthImg,
      healthConnectImg: HealthConnectImg,
      scountImg: ScountImgMiddle,
      coinImg: CoinImg,
      ofpTitle: 'ОФП-индекс на основе<br/>рекомендаций ВОЗ',
      ofpSubtitle: 'Еженедельно достигая <b>150 ОФП</b>, вы можете продлить себе жизнь, улучшить качество сна и поднять настроение.',
      ofpDescription: 'Индекс ОФП повышается за каждую минуту активных физических нагрузок, например бега.',
      stepsDescription: 'Шаги обновляются при каждом входе в приложение SCOUNT',
      ofpValues: [],
      guideModal: false,
      showCopied: false,
      targetSteps: 120,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options', 'resources']),
    ...mapGetters('user', ['code', 'profile', 'hasParticipantActiveDeviceSession']),
    ...mapGetters('steps', ['stepsHistory']),
    currentLevel() {
      return this.experience ? this.experience.level : 1;
    },
    currentValue() {
      return this.experience ? this.experience.value : 0;
    },
    nextLevelValue() {
      return this.experience ? this.experience.nextLevelValue : 0;
    },
    currentLevelStartValue() {
      return this.experience ? this.experience.currentLevelStartValue : 0;
    },
    needXP() {
      return this.nextLevelValue - this.currentValue;
    },
    showIndexOFP() {
      return this.options ? this.options.showIndexOFP === true : false;
    },
    showAchievementTypes() {
      return this.options ? this.options.showAchievementTypes || [] : [];
    },
    articleAboutIndexOFP() {
      return this.resources ? this.resources.articleAboutIndexOFP : '#';
    },
    navigationOffset() {
      return {
        default: '0', md: '72', lg: '72', xl: '83',
      };
    },
    noPagination() {
      return true;
    },
    navigationOffset1() {
      return {
        default: '0', md: '72', lg: '72', xl: '83',
      };
    },
    noPagination1() {
      return true;
    },
    initialSlide() {
      return this.ofpValues.length > 0 ? this.ofpValues.length - 1 : 0;
    },
    initialStepsSlide() {
      return this.stepsHistory.length > 0 ? this.stepsHistory.length - 1 : 0;
    },
    walkingAroundRafiki() {
      return this.resources ? this.resources.walkingAroundRafiki || '' : '';
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    guideTitle() {
      return addNonBreakingSpaces('Подключите учёт шагов в приложении СКАУНТ');
    },
    OS() {
      const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
  },
  methods: {
    ...mapActions('user', ['getCode']),
    formatStepsDate(dateString) {
      const date = dayjs(dateString);
      const today = dayjs();
      if (date.isSame(today, 'day')) {
        return 'Шагов сегодня';
      }
      return `Шагов за ${date.format('DD.MM')}`;
    },
    onTargetSteps() {
      this.$emit('set-target-steps');
      this.stepsModal = false;
    },
    onShowStepsModal() {
      if (!this.hasParticipantActiveDeviceSession) {
        this.guideModal = true;
      } else {
        this.stepsModal = true;
      }
    },
    getCodeMp() {
      if (this.profile) {
        this.getCode(this.profile.marketingProgramId)
          .then(() => {
            if (this.responseStatus === 200) {
              this.loading = false;
            }
          });
      }
    },
    onCopyCode() {
      navigator.clipboard.writeText(this.code.code);
      if (this.showCopied) this.showCopied = false;
      setTimeout(() => { this.showCopied = true; }, 500);
    },
    pluralizeSteps(count) {
      if (count % 10 === 1 && count % 100 !== 11) {
        return 'Шаг';
      } if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return 'Шага';
      }
      return 'Шагов';
    },
    onSelected(achievementType) {
      if (achievementType) {
        this.achievementsModal = true;
      }
    },
    onAchievementDetails(item) {
      if (item && !this.$root.isDesktop) {
        this.descriptionImageClass = this.getDescriptionImageClass(item);
        this.descriptionTitle = item.name;
        this.descriptionSubtitle = item.desc;
        this.descriptionModal = true;
      }
    },
    onShowIndexOFP() {
      this.indexOFPModal = true;
    },
    onResize() {
      this.setAchievements();
    },
    onHashChange() {
      if (this.$route.name !== 'Dashboard') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showAchievements') this.showAchievements(hash);
      else if (hash[0] === '#!showOFP') this.showOFP(hash);
      else {
        this.achievementsModal = false;
        this.indexOFPModal = false;
      }
    },
    onSettings() {
      if (this.$props.hideEdit !== true) return;
      this.$router.push({ name: 'Settings' }).catch(() => {});
    },
    onEditAvatar() {
      this.showSetAvatar = true;
    },
    getPercent() {
      if (this.nextLevelValue > 0) {
        let percent = ((this.currentValue - this.currentLevelStartValue) / (this.nextLevelValue - this.currentLevelStartValue)) * 100 + 5;
        if (percent > 100) percent = 100;
        return percent;
      }
      return 5;
    },
    setAchievementsAll() {
      if (this.theme === '' || this.showAchievementTypes.length === 0) {
        this.achievementsAll = AchievementsAll;
        return;
      }
      this.achievementsAll = AchievementsAll.filter((i) => this.showAchievementTypes.includes(i.cat));
    },
    setAchievements() {
      this.activeAchievements = this.getAchievements();
      this.grayAchievements = this.getGrayAchievements();
      let countMore = 0;
      if (!this.$root.isMobile) countMore = this.achievementsAll.length - this.maxDesktopItems;
      else countMore = this.achievementsAll.length - this.maxMobileItems;
      this.countMore = countMore > 0 ? countMore : 0;
    },
    getAchievements() {
      if (this.$props.achievements) {
        if (!this.$root.isMobile) return this.$props.achievements.slice(0, 3);
        return this.$props.achievements.slice(0, 7);
      }
      return [];
    },
    getGrayAchievements() {
      const achievements = this.activeAchievements;
      if (achievements.length > 0) {
        const gray = [];
        for (let i = 0; i < this.achievementsAll.length; i += 1) {
          const found = achievements.filter((it) => it.achievementType === this.achievementsAll[i].achievementType);
          if (found.length > 0) continue;
          gray.push(this.achievementsAll[i]);
        }
        return this.getVisibleItems(gray);
      }
      return this.getVisibleItems(this.achievementsAll);
    },
    getAchievementsOfCat(cat) {
      return this.achievementsAll.filter((i) => i.cat === cat);
    },
    getActiveOfCatCount(cat) {
      const achievements = this.getAchievementsOfCat(cat);
      let count = 0;
      for (let i = 0; i < this.$props.achievements.length; i += 1) {
        const found = achievements.filter((it) => it.achievementType === this.$props.achievements[i].achievementType);
        if (found.length === 0) continue;
        if (found[0].cat === cat) count += 1;
      }
      return count;
    },
    getCountOfCat(cat) {
      return this.achievementsAll.filter((it) => it.cat === cat).length;
    },
    getVisibleItems(items) {
      if (this.activeAchievements.length > 0) {
        let count = 0;
        if (!this.$root.isMobile) count = this.maxDesktopItems - this.activeAchievements.length;
        else count = this.maxMobileItems - this.activeAchievements.length;
        if (count > 0) return items.slice(0, count);
        return [];
      }
      if (!this.$root.isMobile) return items.slice(0, this.maxDesktopItems);
      return items.slice(0, this.maxMobileItems);
    },
    isAchievementActive(achievementType) {
      const found = this.$props.achievements.filter((i) => i.achievementType === achievementType);
      return found.length > 0;
    },
    setCollapsed(id) {
      const index = this.categories.findIndex((i) => i.id === id);
      if (index < 0) return;
      this.categories[index].collapsed = !this.categories[index].collapsed;
    },
    getActiveClass(item, inDialog = false) {
      const className = this.getAchievementClass(item.achievementType);
      if (!inDialog) return `${className}-40`;
      const isActive = this.isAchievementActive(item.achievementType);
      if (this.$root.isMobile) return isActive ? `${className}-96` : `${className}-96-disabled`;
      return isActive ? `${className}-128` : `${className}-128-disabled`;
    },
    getGrayClass(item) {
      const className = this.getAchievementClass(item.achievementType);
      return `${className}-40-disabled`;
    },
    getAchievementClass(achievementType) {
      const found = this.achievementsAll.filter((i) => i.achievementType === achievementType);
      if (found.length === 0) return '';
      return found[0].class;
    },
    getDescriptionImageClass(item) {
      const className = this.getAchievementClass(item.achievementType);
      const isActive = this.isAchievementActive(item.achievementType);
      return isActive ? `${className}-96` : `${className}-96-disabled`;
    },
    setOFPvalues() {
      const values = [];
      if (this.sportIndexes) {
        const items = [];
        if (this.sportIndexes.history) {
          items.push(...this.sportIndexes.history);
        }
        items.push({
          index: this.sportIndexes.index,
          startDate: this.sportIndexes.startDate,
          finishDate: this.sportIndexes.finishDate,
        });
        for (let i = 0; i < items.length; i += 1) {
          const dates = formatDateRange({ start: items[i].startDate, finish: items[i].finishDate, showYear: true });
          values.push({
            value: items[i].index,
            dates,
          });
        }
      }
      this.ofpValues = values;
    },
    formatDateRange(payload) {
      return formatDateRange(payload);
    },
    showAchievements(hash) {
      if (hash[1] === 'true') this.onSelected('Welcome');
      else {
        this.achievementsModal = false;
        this.clearHash();
      }
    },
    showOFP(hash) {
      if (hash[1] === 'true') this.onShowIndexOFP();
      else {
        this.indexOFPModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  mounted() {
    this.getCodeMp();
    this.setAchievementsAll();
    this.setOFPvalues();
    window.addEventListener('resize', this.onResize);
    this.onResize();
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./ProfileExperience";
  @import '../ConnectionGuide/ConnectionGuide';
</style>
