<template>
  <div class="progressbar">
    <svg
      class="progressbar__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
    >
      <circle
        class="progressbar__background"
        cx="12"
        cy="12"
        r="12"
        fill="#E5E7EB"
      />
      <circle
        class="progressbar__progress"
        cx="12"
        cy="12"
        r="9"
        fill="white"
      />
      <circle
        class="progressbar__stroke"
        cx="12"
        cy="12"
        r="10.5"
        :stroke="fillColor"
        :stroke-width="3"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
        transform="rotate(-90 12 12)"
        fill="transparent"
      />

      <path
        class="progressbar__checkmark"
        d="M8.5 12.5L11 15L15.5 10"
        fill="transparent"
        :stroke="checkColor"
        :stroke-width="strokeWidth"
      />
    </svg>
  </div>
</template>

<script>
const colors = {
  success: '#51C41A', // Зеленый
  warning: '#FAAD14', // Оранжевый
  danger: '#ED1C24', // Красный
  default: 'rgba(0,0,0, 0.09)',
};

export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    targetSteps: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    dashArray() {
      const circumference = 2 * Math.PI * 10.5;
      return `${circumference}`;
    },
    dashOffset() {
      const circumference = 2 * Math.PI * 10.5;
      const percentage = Math.min(this.targetSteps, Math.max(0, this.value)) / this.targetSteps;
      return circumference * (1 - percentage);
    },
    strokeWidth() {
      return this.size / 10;
    },
    checkColor() {
      return this.value >= this.targetSteps ? colors.success : colors.default;
    },
    fillColor() {
      const percentage = (this.value / this.targetSteps) * 100;
      if (percentage >= 100) {
        return colors.success; // Зеленый
      } if (percentage >= 75) {
        return colors.success; // Зеленый (75-99%)
      } if (percentage >= 25) {
        return colors.warning; // Оранжевый (25-74%)
      }
      return colors.danger; // Красный (0-24%)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./ProgressBarCircle";
.progressbar {
  // Ваши стили для прогресс-бара
}
</style>
